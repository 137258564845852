//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import PropTypes  from 'prop-types';

import HeaderLogo              from '@assets/images/header-logo.png';
import OrderInfoCheckoutButton from '@connected/OrderInfoCheckoutButton';
import withWindowScrolled      from '@hoc/WindowScrollListener';
import ContentWrapper          from '@stateless/atomic/ContentWrapper';
import Icon                    from '@stateless/atomic/Icon';
import IconType                from '@stateless/atomic/Icon/IconType';
import OpeningHours            from '@stateless/atomic/OpeningHours';
import RestaurantAddress       from '@stateless/atomic/RestaurantAddress';

import styles from './styles.module.scss';

const propTypes = {
    onCheckoutClicked: PropTypes.func,
    onMenuClicked:     PropTypes.func,
    restaurant:        PropTypes.object,
    scrolled:          PropTypes.bool,
};

const Header = ({
    onCheckoutClicked = _.noop,
    onMenuClicked     = _.noop,
    scrolled          = false,
    restaurant        = null,
}) => {
    const openingHours = _.get(restaurant, 'openingHours', []);

    return (
        <>
            <ContentWrapper>
                <div className={styles.headerContainer}>
                    <div className={styles.headerGridColumn}>
                        <img
                            alt={'Logo'}
                            src={HeaderLogo}
                            className={styles.headerLogo}
                        />
                        <div className={styles.menuIcon}>
                            <Icon
                                onClick={onMenuClicked}
                                iconType={IconType.menu}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.headerGridColumn,
                            styles.center,
                            styles.hideMobile,
                        )}
                    >
                        <RestaurantAddress
                            restaurant={restaurant}
                        />
                    </div>
                    <div
                        className={classNames(
                            styles.headerGridColumn,
                            styles.center,
                            styles.hideMobile,
                        )}
                    >
                        <OpeningHours
                            openingHours={openingHours}
                        />
                    </div>
                </div>
            </ContentWrapper>
            <ContentWrapper className={styles.fixedHeader}>
                <div
                    className={classNames(
                        styles.checkoutButtonContainer,
                        {
                            [styles.notTop]: scrolled,
                        },
                    )}
                >
                    <OrderInfoCheckoutButton
                        onClick={onCheckoutClicked}
                    />
                </div>
            </ContentWrapper>
        </>

    );
};

Header.propTypes = propTypes;

export default withWindowScrolled(Header);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Elements }   from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PropTypes        from '@components/PropTypes';
import ApiConfiguration from '@constants/Api';

import styles from './styles.module.scss';

const stripePromise = loadStripe(ApiConfiguration.getStripeKey());

const propTypes = {
    children:     PropTypes.children,
    clientSecret: PropTypes.string,
};

const StripeWrapper = ({
    children     = null,
    clientSecret = null,
}) => {
    function tryToRenderStripElements() {
        if (clientSecret) {
            return (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                    }}
                >
                    {children}
                </Elements>
            );
        }

        return children;
    }

    return (
        <div className={styles.stripePayment}>
            {tryToRenderStripElements()}
        </div>
    );
};

StripeWrapper.propTypes = propTypes;

export default StripeWrapper;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n      from 'i18next';
import { Trans } from 'react-i18next';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Icon         from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    infoItem: PropTypes.shape({
        iconType: PropTypes.iconType,
        textKey:  PropTypes.string,
        titleKey: PropTypes.string,
    }),
};

const InfoBlock = ({
    infoItem = {},
}) => {
    const { iconType, textKey, textKeyParameter, titleKey } = infoItem;
    const translationBasePath                               = 'components.infoOverlay.';

    return (
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockIcon}>
                <Icon iconType={iconType} />
            </div>
            <div className={styles.infoBlockText}>
                <Headline
                    title={I18n.t(`${translationBasePath}${titleKey}`)}
                    type={HeadlineType.headline3}
                />
                <p>
                    <Trans
                        i18nKey={`${translationBasePath}${textKey}`}
                        values={textKeyParameter}
                    >
                        <a href={I18n.t('phoneUrl')} />
                    </Trans>
                </p>
            </div>
        </div>
    );
};

InfoBlock.propTypes = propTypes;

export default InfoBlock;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    LIVE: 'pk_live_51OJxFHCwmcQku8qBSgXDf4LXFqCXAKdfvuRrv8iFAPyl9Ai4QDiQveumKZ8XTAFoLclVw2t7zYu4DUNLHbGQ3WVl00D2ROB9Rc',
    TEST: 'pk_test_51OJxFHCwmcQku8qB9rIlIwxIcGQY2PabLORqTYGnsdPgBu7YQW4vLY1YSnYyvaPNGlUdtAEAh4AF0BQh21AXfK1I00UtuhnV82',
});

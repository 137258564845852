//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api             from '@api';
import Hydra                from '@helper/Hydra';
import { AllergensActions } from '@slices/allergens';

function* fetchAllergens(action) {
    const response = yield call(Api.context.allergens.fetch);

    if (response.ok) {
        const allergens = Hydra.getMembersFromResponse(response.data);

        yield put(AllergensActions.fetchAllergensSucceeded({
            allergens,
        }));
    } else {
        yield put(AllergensActions.fetchAllergensFailed());
    }
}

export default {
    fetchAllergens,
};

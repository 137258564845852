//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api                      from '@api/index';
import Hydra                         from '@helper/Hydra';
import { DeliveryExceptionsActions } from '@slices/deliveryExceptions';

function* fetchDeliveryExceptions() {
    const response = yield call(Api.context.deliveryExceptions.fetch);

    if (response.ok) {
        const deliveryExceptions = Hydra.getMembersFromResponse(response.data);

        yield put(DeliveryExceptionsActions.fetchDeliveryExceptionsSucceeded({
            deliveryExceptions,
        }));
    } else {
        yield put(DeliveryExceptionsActions.fetchDeliveryExceptionsFailed());
    }
}

export default {
    fetchDeliveryExceptions,
};

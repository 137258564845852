//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                             from '@components/PropTypes';
import ServingType                           from '@constants/ServingType';
import DateTime                              from '@helper/DateTime';
import TimeSlotHelper                        from '@helper/TimeSlotHelper';
import { OrdersActions }                     from '@slices/orders';
import Button                                from '@stateless/atomic/Button';
import ButtonColor                           from '@stateless/atomic/Button/ButtonColor';
import ButtonType                            from '@stateless/atomic/Button/ButtonType';
import IconType                              from '@stateless/atomic/Icon/IconType';
import { selectActiveAppSetting }            from '@store/selectors/appSettings';
import { selectCurrentOrderServingType }     from '@store/selectors/orders';
import { selectCurrentOrderTimeSlot }        from '@store/selectors/orders';
import { selectNextDeliveryEnabledTimeslot } from '@store/selectors/timeSlots';

const propTypes = {
    servingType: PropTypes.oneOfObjectValues(ServingType),
};

const ServingTypeButton = ({
    servingType = null,
}) => {
    const dispatch                    = useDispatch();
    const currentOrderServingType     = useSelector(selectCurrentOrderServingType);
    const currentOrderTimeSlot        = useSelector(selectCurrentOrderTimeSlot);
    const nextDeliveryEnabledTimeSlot = useSelector(selectNextDeliveryEnabledTimeslot(currentOrderTimeSlot));
    const activeAppSetting            = useSelector(selectActiveAppSetting);
    const buttonColor                 = currentOrderServingType === servingType
        ? ButtonColor.black
        : ButtonColor.gray;
    const disabled                    = (
        servingType === ServingType.delivery &&
        (
            !currentOrderTimeSlot?.deliveryEnabled ||
            currentOrderTimeSlot?.deliveryBlocked ||
            !activeAppSetting?.deliveryEnabled
        )
    );

    function getDisabledHoverText() {
        if (!disabled) {
            return null;
        }

        if (!activeAppSetting?.deliveryEnabled) {
            return I18n.t('deliveryNotAvailable');
        }

        const nextFreeDateTime = nextDeliveryEnabledTimeSlot?.startDateTime;

        return I18n.t('noDeliveryInThisTimeSlot', {
            nextFreeTimeSlotDate: TimeSlotHelper.getDateString(nextFreeDateTime),
            nextFreeTimeSlotTime: DateTime.getHoursAndMinutes(nextFreeDateTime),
        });
    }

    function getIconType() {
        switch (servingType) {
            case ServingType.clickAndCollect:
                return IconType.checkout;
            case ServingType.delivery:
                return IconType.delivery;
            default:
                return null;
        }
    }

    function getButtonText() {
        switch (servingType) {
            case ServingType.clickAndCollect:
                return I18n.t('clickAndCollect');
            case ServingType.delivery:
                return I18n.t('delivery');
            default:
                return null;
        }
    }

    function handleButtonClicked() {
        dispatch(OrdersActions.setCurrentOrderServingType({
            servingType,
        }));
    }

    return (
        <Button
            type={ButtonType.pill}
            text={getButtonText()}
            color={buttonColor}
            disabled={disabled}
            iconLeft={getIconType()}
            onClick={handleButtonClicked}
            disabledHoverText={getDisabledHoverText()}
        />
    );
};

ServingTypeButton.propTypes = propTypes;

export default ServingTypeButton;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes                from '@components/PropTypes';
import StatelessCheckoutOverlay from '@stateless/composed/CheckoutOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const CheckoutOverlay = ({
    onCloseClick = _.noop,
    open         = false,
}) => {
    return (
        <StatelessCheckoutOverlay
            onClose={onCloseClick}
            open={open}
        />
    );
};

CheckoutOverlay.propTypes = propTypes;

export default CheckoutOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import StripeKey   from '@constants/StripeKey';
import Environment from '@helper/Environment';

import ApiUrls from './ApiUrls';

const PRODUCTION_BACKEND_URL = 'https://api.pizza.clausgemacht.de';

const STAGING_BACKEND_URL = 'https://api.pizza.staging.clausgemacht.org';

const LOCAL_BACKEND_URL = 'https://clausgemacht-order-app-backend.test';

const PRODUCTION_KITCHEN_URL = 'https://kueche.clausgemacht.de';

const STAGING_KITCHEN_URL = 'https://kueche.staging.clausgemacht.org';

const LOCAL_KITCHEN_URL = 'http://clausgemacht-kitchen-app.test:3001';

export default class Api {
    static localUrlIndicators = [
        '.test',
        'localhost',
        '127.0.0.1',
        'ngrok.io',
        'loca.lt',
    ];

    static getBackendUrl() {
        const url = window.location.href;

        for (const localUrlIndicator of Api.localUrlIndicators) {
            if (url.indexOf(localUrlIndicator) > -1) {
                return LOCAL_BACKEND_URL;
            }
        }

        if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    }

    static getKitchenUrl() {
        const url = window.location.href;

        for (const localUrlIndicator of Api.localUrlIndicators) {
            if (url.indexOf(localUrlIndicator) > -1) {
                return LOCAL_KITCHEN_URL;
            }
        }

        if (url.indexOf('staging.') > -1) {
            return STAGING_KITCHEN_URL;
        }

        return PRODUCTION_KITCHEN_URL;
    }

    /**
     * @returns {boolean}
     */
    static isStaging() {
        return (
            this.getBackendUrl() === STAGING_BACKEND_URL
        );
    }

    /**
     * @returns {boolean}
     */
    static isDevelopment() {
        return (
            this.getBackendUrl() === LOCAL_BACKEND_URL
        );
    }

    /**
     * @returns {boolean}
     */
    static isProduction() {
        return (
            this.getBackendUrl() === PRODUCTION_BACKEND_URL
        );
    }

    /**
     * @returns {string}
     */
    static getStripeKey() {
        return (
            this.isProduction() ?
                StripeKey.LIVE :
                StripeKey.TEST
        );
    }

    /**
     * @param host
     */
    static checkBackendUrl(host) {
        const isTestEnvironment = Environment.isTest();
        const showWarning       = !isTestEnvironment;

        if (
            host.endsWith('/') &&
            showWarning
        ) {
            console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /', host);
        }

        if (
            host !== PRODUCTION_BACKEND_URL &&
            showWarning
        ) {
            console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
        }

        if (
            host.indexOf('https://') === -1 &&
            showWarning
        ) {
            console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
        }
    }

    /**
     * @returns {[]}
     */
    static getAllUrls() {
        const apis = [];

        Object.keys(ApiUrls).forEach((key) => {
            const url = ApiUrls[key];

            if (typeof url !== 'function') {
                apis.push(url);
            }
        });

        return apis;
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import moment from 'moment-timezone';

import Weekdays from '@constants/Weekdays';

class OpeningHourHelper {
    static unifyWeekdays(openingHourItems) {
        const openingHourDisplayItems = [];

        openingHourItems.forEach((openingHourItem) => {
            const weekDay                = openingHourItem.weekDay;
            const openingHourEntries     = openingHourItem.openingHourEntries;
            const openingHourDisplayItem = _.find(openingHourDisplayItems, {
                openingHourEntries,
            });

            if (openingHourDisplayItem) {
                openingHourDisplayItem.weekDays.push(weekDay);
            } else {
                openingHourDisplayItems.push({
                    weekDays: [weekDay],
                    openingHourEntries,
                });
            }
        });

        return openingHourDisplayItems;
    }

    static formatOpeningHours(openingHours) {
        const weekDayGroups    = _.groupBy(openingHours, 'weekday');
        const openingHourItems = [];

        _.keys(Weekdays).forEach((weekDay) => {
            const weekdayGroup = weekDayGroups[weekDay];

            if (!weekdayGroup) {
                return;
            }

            const openingHourEntries = weekdayGroup.map((openingHour) => {
                const openTime  = moment(openingHour.openTime).format('HH:mm');
                const closeTime = moment(openingHour.closeTime).format('HH:mm');

                return {
                    openTime,
                    closeTime,
                    deliveryEnabled: openingHour.deliveryEnabled,
                    noDeliveryLabel: openingHour.noDeliveryLabel,
                };
            });

            openingHourEntries.sort((first, second) => {
                if (first.openTime < second.openTime) {
                    return -1;
                }

                if (first.openTime > second.openTime) {
                    return 1;
                }

                return 0;
            });

            openingHourItems.push({
                weekDay,
                openingHourEntries,
            });
        });

        return OpeningHourHelper.unifyWeekdays(openingHourItems);
    }
}

export default OpeningHourHelper;

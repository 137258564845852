//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n      from 'i18next';
import _         from 'lodash';
import { Trans } from 'react-i18next';

import FooterLogo          from '@assets/images/footer-logo.png';
import PropTypes           from '@components/PropTypes';
import ContentWrapper      from '@stateless/atomic/ContentWrapper';
import { informationData } from '@stateless/atomic/Footer/FooterData';
import { contactData }     from '@stateless/atomic/Footer/FooterData';
import FooterListItem      from '@stateless/atomic/FooterListItem';
import Headline            from '@stateless/atomic/Headline';
import HeadlineColor       from '@stateless/atomic/Headline/HeadlineColor';
import HeadlineType        from '@stateless/atomic/Headline/HeadlineType';
import IconType            from '@stateless/atomic/Icon/IconType';
import LinkIcon            from '@stateless/atomic/LinkIcon';

import styles from './styles.module.scss';

const propTypes = {
    restaurant: PropTypes.object,
};

const Footer = ({
    restaurant = null,
}) => {
    const baseTranslationKey = 'components.footer.';

    function renderFooterItems(data) {
        const title = I18n.t(`${baseTranslationKey}${data.titleKey}`);
        const items = _.map(data.linkItems, (item) => {
            const text = I18n.t(item.translationKey);
            const href = I18n.t(item.hrefKey);

            return (
                <FooterListItem
                    key={text}
                    text={text}
                    href={href}
                />
            );
        });

        return [
            <Headline
                key={title}
                type={HeadlineType.headline2}
                title={title}
                color={HeadlineColor.orange}
            />,
            ...items,
        ];
    }

    function renderAddress() {
        if (!restaurant) {
            return null;
        }

        const address = restaurant.address;

        if (!address) {
            return null;
        }

        const { street, houseNumber, postalCode, city } = address;

        return (
            <div>
                <p>
                    {street} {houseNumber}
                </p>
                <p>
                    {postalCode} {city}
                </p>
            </div>
        );
    }

    return (
        <div className={styles.footer}>
            <ContentWrapper>
                <div className={styles.footerGrid}>
                    <div className={styles.footerLogoItem}>
                        <img
                            className={styles.footerLogo}
                            alt={'Logo'}
                            src={FooterLogo}
                        />
                        <p>
                            {I18n.t(`${baseTranslationKey}summerIce`)}
                        </p>
                        <p>
                            {I18n.t(`${baseTranslationKey}winterPizza`)}
                        </p>
                        <div>
                            <Trans i18nKey={`${baseTranslationKey}lulububuLove`}>
                                <span />
                                <a
                                    href={I18n.t('lulububuUrl')}
                                    target="_blank"
                                    rel="noreferrer"
                                />
                            </Trans>
                        </div>
                        <LinkIcon
                            className={styles.footerLogoLinkItem}
                            href={I18n.t('instagramUrl')}
                            target={'_blank'}
                            iconType={IconType.instagram}
                        />
                    </div>
                    <div className={styles.footerInfoContainer}>
                        {renderFooterItems(informationData)}
                    </div>
                    <div className={styles.footerInfoContainer}>
                        {renderFooterItems(contactData)}
                        {renderAddress()}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

Footer.propTypes = propTypes;

export default Footer;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                      from '@components/PropTypes';
import CheckoutButton                 from '@connected/CheckoutButton';
import Routes                         from '@constants/Routes';
import usePrevious                    from '@helper/CustomHooks';
import StatelessOrderInfoButton       from '@stateless/composed/OrderInfoButton';
import selectCurrentRoute             from '@store/selectors/navigation';
import { selectCurrentOrder }         from '@store/selectors/orders';
import { selectCurrentOrderTimeSlot } from '@store/selectors/orders';

const propTypes = {
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
};

const timeoutDurationInMilliseconds = 4000;

const OrderInfoCheckoutButton = ({
    onClick  = _.noop,
    disabled = false,
}) => {
    const currentOrder                          = useSelector(selectCurrentOrder);
    const route                                 = useSelector(selectCurrentRoute);
    const [expanded, setExpanded]               = React.useState(false);
    const [expandedTimeout, setExpandedTimeout] = React.useState(null);
    const previousOrder                         = usePrevious(currentOrder);
    const selectedTimeSlot                      = useSelector(selectCurrentOrderTimeSlot);

    useEffect(
        () => {
            const timeslotChanged = (
                previousOrder &&
                previousOrder.timeSlot !== currentOrder.timeSlot
            );
            const pizzasChanged   = (
                previousOrder &&
                previousOrder.orderProducts.length !== currentOrder.orderProducts.length
            );

            if (
                timeslotChanged ||
                pizzasChanged
            ) {
                clearTimeout(expandedTimeout);
                setExpanded(true);
                setExpandedTimeout(setTimeout(() => {
                    setExpanded(false);
                }, timeoutDurationInMilliseconds));
            }
        },
        [currentOrder],
    );

    return (
        <StatelessOrderInfoButton
            currentOrder={currentOrder}
            show={route === Routes.home}
            expanded={expanded}
            selectedTimeSlot={selectedTimeSlot}
        >
            <CheckoutButton
                disabled={disabled}
                onClick={onClick}
            />
        </StatelessOrderInfoButton>
    );
};

OrderInfoCheckoutButton.propTypes = propTypes;

export default OrderInfoCheckoutButton;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import usePrevious                         from '@helper/CustomHooks';
import { OrdersActions }                   from '@slices/orders';
import { selectCurrentOrderOrderProducts } from '@store/selectors/orders';

const OrderPriceCalculator = () => {
    const dispatch          = useDispatch();
    const orderProducts     = useSelector(selectCurrentOrderOrderProducts);
    const prevOrderProducts = usePrevious(orderProducts);

    function orderContentHasChanged() {
        if (
            !orderProducts ||
            !prevOrderProducts
        ) {
            return false;
        }

        if (orderProducts.length !== prevOrderProducts.length) {
            return true;
        }

        for (let productIndex = 0; productIndex < orderProducts.length; productIndex++) {
            const orderProduct     = orderProducts[productIndex];
            const prevOrderProduct = prevOrderProducts[productIndex];

            if (orderProduct.id !== prevOrderProduct.id) {
                return true;
            }

            if (orderProduct.quantity !== prevOrderProduct.quantity) {
                return true;
            }

            if (orderProduct.productAdditions.length !== prevOrderProduct.productAdditions.length) {
                return true;
            }

            for (let additionIndex = 0; additionIndex < orderProduct.productAdditions.length; additionIndex++) {
                const orderProductAddition     = orderProduct.productAdditions[additionIndex];
                const prevOrderProductAddition = prevOrderProduct.productAdditions[additionIndex];

                if (orderProductAddition.id !== prevOrderProductAddition.id) {
                    return true;
                }
            }
        }

        return false;
    }

    useEffect(
        () => {
            if (orderContentHasChanged()) {
                dispatch(OrdersActions.calculateOrderPrice());
            }
        },
        [orderProducts],
    );

    return null;
};

export default OrderPriceCalculator;

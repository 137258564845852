//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import PropTypes    from '@components/PropTypes';
import ProductType  from '@constants/ProductType';
import Url          from '@helper/Url';
import CircleIcon   from '@stateless/atomic/CircleIcon';
import CircleColor  from '@stateless/atomic/CircleIcon/CircleColor';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Icon         from '@stateless/atomic/Icon';
import IconType     from '@stateless/atomic/Icon/IconType';
import Price        from '@stateless/atomic/Price';

import styles from './styles.module.scss';

const propTypes = {
    addProductToCartClicked: PropTypes.func,
    onProductInfoClicked:    PropTypes.func,
    product:                 PropTypes.object,
    small:                   PropTypes.bool,
};

const ProductItem = ({
    product                 = null,
    small                   = false,
    onProductInfoClicked    = _.noop,
    addProductToCartClicked = _.noop,
}) => {
    const { name, description, vegan, vegetarian, alcoholic, price, image, type } = product;
    const baseTranslationPath                                                     = 'components.productItem.';

    function getLabelKey() {
        if (type === ProductType.drink) {
            if (alcoholic) {
                return 'alcoholic';
            }

            return 'nonAlcoholic';
        }

        if (type === ProductType.pizza) {
            if (vegan) {
                return 'vegan';
            }

            if (vegetarian) {
                return 'vegetarian';
            }
        }

        return null;
    }

    function renderProductInfoLabel() {
        const labelKey = getLabelKey();

        if (labelKey === null) {
            return null;
        }

        if (type === ProductType.pizza) {
            return (
                <div
                    className={classNames(
                        styles.greenLabel,
                        styles.productInfoLabel,
                    )}
                >
                    <p>
                        {I18n.t(baseTranslationPath + labelKey)}
                    </p>
                    <Icon iconType={IconType.vegan} />
                </div>
            );
        }

        if (type === ProductType.drink) {
            return (
                <div
                    className={styles.productInfoLabel}
                >
                    <p>
                        {I18n.t(baseTranslationPath + labelKey)}
                    </p>
                </div>
            );
        }

        return null;
    }

    function renderDescription() {
        if (small) {
            return null;
        }

        return (
            <p className={styles.description}>
                {description}
            </p>
        );
    }

    function renderProductInfoIcon() {
        if (product.allergens.length === 0) {
            return null;
        }

        return (
            <CircleIcon
                iconType={IconType.info}
                onClick={onProductInfoClicked}
                circleColor={CircleColor.transparent}
            />
        );
    }

    return (
        <div
            className={classNames(
                styles.productItem,
                {
                    [styles.small]: small,
                },
            )}
        >
            <img
                alt={image.path}
                src={Url.resizedImage(image.path, 300)}
            />
            <div className={styles.productItemContent}>
                <div className={styles.productItemTitle}>
                    <Headline
                        type={HeadlineType.headline2}
                        title={name}
                    />
                    {renderProductInfoIcon()}
                </div>
                {renderProductInfoLabel()}
                {renderDescription()}
                <div className={styles.productAction}>
                    <Price value={price} />
                    <CircleIcon
                        iconType={IconType.plus}
                        circleColor={CircleColor.orange}
                        onClick={addProductToCartClicked}
                    />
                </div>
            </div>
        </div>
    );
};

ProductItem.propTypes = propTypes;

export default ProductItem;

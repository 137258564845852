//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }  from 'connected-react-router';
import _         from 'lodash';
import { call }  from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { put }   from 'redux-saga/effects';

import * as Api                       from '@api/index';
import Routes                         from '@constants/Routes';
import DateTime                       from '@helper/DateTime';
import Hydra                          from '@helper/Hydra';
import SagaStateHelper                from '@helper/SagaStateHelper';
import { AppSettingsActions }         from '@slices/appSettings';
import { OrdersActions }              from '@slices/orders';
import selectCurrentRoute             from '@store/selectors/navigation';
import { selectCurrentOrderTimeSlot } from '@store/selectors/orders';

const APPSETTINGS_FETCH_INTERVAL_IN_MILLISECONDS = 1000 * 10;

function* fetchSettingsInInterval() {
    yield put(AppSettingsActions.fetchAppSettings());
    yield delay(APPSETTINGS_FETCH_INTERVAL_IN_MILLISECONDS);
    yield put(AppSettingsActions.fetchSettingsInInterval());
}

function* fetchAppSettings() {
    const response = yield call(Api.context.appSettings.fetch);

    if (response.ok) {
        const appSettings = Hydra.getMembersFromResponse(response.data);

        yield put(AppSettingsActions.fetchAppSettingsSucceeded({
            appSettings,
        }));
    } else {
        yield put(AppSettingsActions.fetchAppSettingsFailed());
    }
}

function* fetchAppSettingsSucceeded(action) {
    const { appSettings }  = action.payload;
    const activeAppSetting = _.find(appSettings, {
        active: true,
    });

    yield put(AppSettingsActions.setActiveAppSetting(activeAppSetting));

    const {
        enablePizzaBooking,
        soldOut,
    }     = activeAppSetting;
    const route = yield SagaStateHelper.selectBySelector(selectCurrentRoute);

    if (
        !enablePizzaBooking &&
        route !== Routes.deactivated
    ) {
        yield put(push(Routes.deactivated));
    }

    if (
        enablePizzaBooking &&
        route === Routes.deactivated
    ) {
        yield put(push(Routes.home));
    }

    const selectedTimeSlot = yield SagaStateHelper.selectBySelector(selectCurrentOrderTimeSlot);

    if (!selectedTimeSlot) {
        return;
    }

    const isTimeSlotToday = DateTime.isToday(selectedTimeSlot?.startDateTime);

    if (
        soldOut &&
        isTimeSlotToday
    ) {
        yield put(OrdersActions.clearReservedTimeSlot({
            notificationKey: 'timeSlotSoldOut',
        }));
    }
}

export default {
    fetchAppSettings,
    fetchAppSettingsSucceeded,
    fetchSettingsInInterval,
};

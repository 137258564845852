//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { PaymentElement } from '@stripe/react-stripe-js';
import classNames         from 'classnames';
import I18n               from 'i18next';
import _                  from 'lodash';
import { Trans }          from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';

import PropTypes          from '@components/PropTypes';
import AddressForm        from '@connected/AddressForm';
import PaymentTypeButton  from '@connected/PaymentTypeButton';
import ServingTypeButton  from '@connected/ServingTypeButton';
import OrderSource        from '@constants/OrderSource';
import PaymentType        from '@constants/PaymentType';
import Routes             from '@constants/Routes';
import ServingType        from '@constants/ServingType';
import DateTime           from '@helper/DateTime';
import OrderValidator     from '@helper/OrderValidator';
import TimeSlotHelper     from '@helper/TimeSlotHelper';
import Button             from '@stateless/atomic/Button';
import ButtonColor        from '@stateless/atomic/Button/ButtonColor';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import CheckBox           from '@stateless/atomic/CheckBox';
import CircleIcon         from '@stateless/atomic/CircleIcon';
import CircleColor        from '@stateless/atomic/CircleIcon/CircleColor';
import CircleSize         from '@stateless/atomic/CircleIcon/CircleSize';
import Headline           from '@stateless/atomic/Headline';
import HeadlineType       from '@stateless/atomic/Headline/HeadlineType';
import HoverTextIcon      from '@stateless/atomic/HoverTextIcon';
import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';
import Spacer             from '@stateless/atomic/Spacer';
import CheckoutSteps      from '@stateless/composed/CheckoutContent/CheckoutSteps';
import OrderPosition      from '@stateless/composed/OrderPosition';
import PriceCalculation   from '@stateless/composed/PriceCalculation';
import ProductSlider      from '@stateless/composed/ProductSlider';
import StripeCheckoutForm from '@stateless/composed/StripeCheckoutForm';
import StripeWrapper      from '@stateless/composed/StripeWrapper';
import TrackButton        from '@stateless/composed/TrackButton';

import styles from './styles.module.scss';

const propTypes = {
    additionalRequests:               PropTypes.array,
    agbAccepted:                      PropTypes.bool,
    allProducts:                      PropTypes.array,
    appSetting:                       PropTypes.object,
    createAddProductClicked:          PropTypes.func,
    createDecrementProductClicked:    PropTypes.func,
    createIncrementProductClicked:    PropTypes.func,
    createOnAdditionalRequestClicked: PropTypes.func,
    createOnCustomerCommentChanged:   PropTypes.func,
    createOnProductAdditionClicked:   PropTypes.func,
    currentOrder:                     PropTypes.object,
    isModal:                          PropTypes.bool,
    onSubmitOrderClicked:             PropTypes.func,
    open:                             PropTypes.bool,
    orderSource:                      PropTypes.oneOfObjectValues(OrderSource),
    overlayContentRef:                PropTypes.object,
    paymentClientSecret:              PropTypes.string,
    productAdditions:                 PropTypes.array,
    recommendedProducts:              PropTypes.array,
    selectedAdditionalRequests:       PropTypes.array,
    selectedPaymentType:              PropTypes.oneOfObjectValues(PaymentType),
    selectedServingType:              PropTypes.oneOfObjectValues(ServingType),
    setAgbAccepted:                   PropTypes.func,
    submitPaymentCanceled:            PropTypes.func,
};

const CheckoutContent = ({
    isModal                          = false,
    overlayContentRef                = null,
    additionalRequests               = [],
    open                             = false,
    productAdditions                 = [],
    selectedServingType              = '',
    recommendedProducts              = [],
    allProducts                      = [],
    currentOrder                     = null,
    appSetting                       = null,
    createAddProductClicked          = _.noop,
    createIncrementProductClicked    = _.noop,
    createDecrementProductClicked    = _.noop,
    createOnProductAdditionClicked   = _.noop,
    createOnCustomerCommentChanged   = _.noop,
    createOnAdditionalRequestClicked = _.noop,
    selectedAdditionalRequests       = [],
    onSubmitOrderClicked             = _.noop,
    submitPaymentCanceled            = _.noop,
    selectedPaymentType              = '',
    paymentClientSecret              = '',
    orderSource                      = '',
    agbAccepted                      = false,
    setAgbAccepted                   = _.noop,
}) => {
    const { t: translator }               = useTranslation(null, {
        keyPrefix: 'components.checkout',
    });
    const [checkoutStep, setCheckoutStep] = useState(CheckoutSteps.Basket);
    const orderProducts                   = currentOrder?.orderProducts ?? [];

    function scrollToTop() {
        let target = window;

        if (isModal) {
            target = overlayContentRef.current;
        }

        target.scrollTo({
            top: 0,
        });
    }

    function changeCheckoutStep(step) {
        setCheckoutStep(step);
        scrollToTop();
    }

    useEffect(() => {
        if (paymentClientSecret) {
            changeCheckoutStep(CheckoutSteps.Payment);
        }
    }, [paymentClientSecret]);

    useEffect(() => {
        if (
            isModal &&
            open
        ) {
            if (paymentClientSecret) {
                changeCheckoutStep(CheckoutSteps.Payment);

                return;
            }

            changeCheckoutStep(CheckoutSteps.Basket);
        }
    }, [open]);

    function toggleAgbAcceptedClicked() {
        setAgbAccepted(!agbAccepted);
    }

    function renderOrderPositions() {
        return _.map(orderProducts, (orderProduct) => {
            const product                  = _.find(
                allProducts,
                {
                    iri: orderProduct.product,
                },
            );
            const onIncrementClicked       = createIncrementProductClicked(orderProduct);
            const onDecrementClicked       = createDecrementProductClicked(orderProduct);
            const onCustomerCommentChanged = createOnCustomerCommentChanged(orderProduct);

            return (
                <React.Fragment
                    key={orderProduct.id}
                >
                    <OrderPosition
                        product={product}
                        productAdditions={productAdditions}
                        positionSum={orderProduct.positionPrice}
                        quantity={orderProduct.quantity}
                        onDecrementClicked={onDecrementClicked}
                        onIncrementClicked={onIncrementClicked}
                        orderProduct={orderProduct}
                        selectedProductAdditions={orderProduct.productAdditions}
                        createOnProductAdditionClicked={createOnProductAdditionClicked}
                        onCustomerCommentChanged={onCustomerCommentChanged}
                    />
                    <Spacer
                        line={true}
                        height={20}
                    />
                </React.Fragment>
            );
        });
    }

    function renderSimpleOrderPositions() {
        return _.map(orderProducts, (orderProduct) => {
            const product = _.find(
                allProducts,
                {
                    iri: orderProduct.product,
                },
            );

            return (
                <React.Fragment
                    key={orderProduct.id}
                >
                    <OrderPosition
                        displayOnly={true}
                        product={product}
                        productAdditions={productAdditions}
                        positionSum={orderProduct.positionPrice}
                        quantity={orderProduct.quantity}
                        orderProduct={orderProduct}
                        selectedProductAdditions={orderProduct.productAdditions}
                    />
                    <Spacer
                        line={true}
                        height={20}
                    />
                </React.Fragment>
            );
        });
    }

    function onBasketNextClick() {
        changeCheckoutStep(CheckoutSteps.Details);
    }

    function onDetailsBackClick() {
        changeCheckoutStep(CheckoutSteps.Basket);
    }

    function renderToHomeButton() {
        if (isModal) {
            return null;
        }

        return (
            <Link
                className={styles.screenBackButton}
                to={Routes.home}
            >
                <CircleIcon
                    circleColor={CircleColor.black}
                    iconType={IconType.arrowLeft}
                    circleSize={CircleSize.big}
                />
            </Link>
        );
    }

    function onSubmitPaymentCanceledClicked() {
        submitPaymentCanceled();
    }

    function renderToPaymentCancelButton() {
        if (isModal) {
            return null;
        }

        return (
            <Link
                className={styles.screenBackButton}
                onClick={onSubmitPaymentCanceledClicked}
            >
                <CircleIcon
                    circleColor={CircleColor.black}
                    iconType={IconType.cross}
                    circleSize={CircleSize.big}
                />
            </Link>
        );
    }

    function renderBackToBasketButton() {
        if (!isModal) {
            return null;
        }

        return (
            <Button
                type={ButtonType.text}
                iconLeft={IconType.arrowLeft}
                text={I18n.t('back')}
                onClick={onDetailsBackClick}
                textColor={ButtonColor.orange}
            />
        );
    }

    function renderAdditionalRequests() {
        return additionalRequests.map((request) => {
            if (
                request.exclusiveServingType &&
                request.exclusiveServingType !== selectedServingType
            ) {
                return null;
            }

            const onAdditionalRequestClicked = createOnAdditionalRequestClicked(request);
            const isChecked                  = selectedAdditionalRequests.includes(request.iri);

            function renderInfoIcon() {
                if (!request.hintText) {
                    return null;
                }

                return (
                    <HoverTextIcon
                        hoverText={request.hintText}
                        iconType={IconType.info}
                    />
                );
            }

            return (
                <div
                    key={request.iri}
                    className={styles.additionalRequestItem}
                >
                    <CheckBox
                        checked={isChecked}
                        key={request.iri}
                        text={request.description}
                        onClick={onAdditionalRequestClicked}
                    />
                    {renderInfoIcon()}
                </div>
            );
        });
    }

    function createTermsAndConditionsText() {
        return (
            <Trans i18nKey={'components.checkout.termsAndConditions'}>
                <a
                    href={I18n.t('termsUrl')}
                    target="_blank"
                    rel="noopener noreferrer"
                />
                <a
                    href={I18n.t('cancellationPolicy')}
                    target="_blank"
                    rel="noopener noreferrer"
                />
            </Trans>
        );
    }

    function renderAcceptAgbCheckbox() {
        if (orderSource === OrderSource.store) {
            return null;
        }

        return (
            <CheckBox
                checked={agbAccepted}
                onClick={toggleAgbAcceptedClicked}
                text={createTermsAndConditionsText()}
            />
        );
    }

    function renderMobileBackToBasketButton() {
        if (isModal) {
            return null;
        }
        return (
            <div className={styles.screenBackButton}>
                <CircleIcon
                    circleColor={CircleColor.black}
                    iconType={IconType.arrowLeft}
                    circleSize={CircleSize.big}
                    onClick={onDetailsBackClick}
                />
            </div>
        );
    }

    function onValidateClicked() {
        return OrderValidator.isOrderValid(
            currentOrder,
            agbAccepted,
            appSetting.minimumOrderValueForDeliveries,
            orderSource,
        );
    }

    function renderOnlinePaymentButton() {
        if (orderSource === OrderSource.store) {
            return null;
        }

        return (
            <PaymentTypeButton paymentType={PaymentType.online} />
        );
    }

    function renderDetailsStep() {
        if (checkoutStep !== CheckoutSteps.Details) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.checkoutContainer,
                    styles.fromRight,
                )}
            >
                {renderMobileBackToBasketButton()}
                <Headline
                    title={I18n.t('contactInformation')}
                    type={HeadlineType.headline1}
                />
                <AddressForm
                    isCheckout={true}
                />
                <div className={styles.detailsGrid}>
                    <div>
                        <Headline
                            title={translator('payment')}
                            type={HeadlineType.headline2}
                        />
                        <p>
                            {translator('paymentText')}
                        </p>
                        <div className={styles.buttonWrapper}>
                            {renderOnlinePaymentButton()}
                            <PaymentTypeButton paymentType={PaymentType.offline} />
                        </div>
                    </div>
                    {
                        selectedPaymentType &&
                        <div className={styles.paymentCompleteWrapper}>
                            {renderAdditionalRequests()}
                            {renderAcceptAgbCheckbox()}
                        </div>
                    }
                </div>
                <div
                    className={styles.orderButton}
                >
                    {renderBackToBasketButton()}
                    <TrackButton
                        value={56}
                        trackText={translator('orderWithCosts')}
                        subText={I18n.t('clickAndCollect')}
                        onClick={onSubmitOrderClicked}
                        onValidate={onValidateClicked}
                        disabled={!OrderValidator.isOrderComplete(currentOrder, orderSource)}
                    />
                </div>
            </div>
        );
    }

    function renderTimeSlotString() {
        const timeSlot = currentOrder?.timeSlot ?? null;

        if (!timeSlot) {
            return null;
        }

        const date       = new Date(timeSlot.startDateTime);
        const dateString = TimeSlotHelper.getDateStringLong(date);
        const timeString = DateTime.getHoursAndMinutes(date);

        return (
            <p>
                {translator(
                    'orderSubText',
                    {
                        date: dateString,
                        time: timeString,
                    },
                )}
            </p>
        );
    }

    function renderBasketStep() {
        if (checkoutStep !== CheckoutSteps.Basket) {
            return null;
        }

        let buttonDisabled     = false;
        let buttonDisabledText = '';

        if (orderProducts.length === 0) {
            buttonDisabled     = true;
            buttonDisabledText = translator('noProducts');
        } else if (!selectedServingType) {
            buttonDisabled     = true;
            buttonDisabledText = translator('noServingTypeSelected');
        } else if (
            selectedServingType === ServingType.delivery &&
            currentOrder.totalPrice < appSetting.minimumOrderValueForDeliveries
        ) {
            buttonDisabled     = true;
            buttonDisabledText = translator(
                'minimumOrderValue',
                {
                    minimumOrderValue: appSetting.minimumOrderValueForDeliveries,
                },
            );
        }

        return (
            <div
                className={classNames(
                    styles.checkoutContainer,
                    styles.fromLeft,
                )}
            >
                {renderToHomeButton()}
                <Headline
                    title={I18n.t('checkoutHeadline')}
                    type={HeadlineType.headline1}
                />
                <div className={styles.orderText}>
                    {renderTimeSlotString()}
                    <Icon iconType={IconType.calendar} />
                </div>
                <div className={styles.buttonWrapper}>
                    <ServingTypeButton servingType={ServingType.delivery} />
                    <ServingTypeButton servingType={ServingType.clickAndCollect} />
                </div>
                <div className={styles.checkoutGrid}>
                    <div>
                        {renderOrderPositions()}
                    </div>
                    <div className={styles.mobileProductsContainer}>
                        <Headline
                            type={HeadlineType.headline2}
                            title={translator('drinksHeadline')}
                        />
                        <ProductSlider
                            products={recommendedProducts}
                            isMobile={true}
                            createAddProductClicked={createAddProductClicked}
                        />
                    </div>
                    <PriceCalculation
                        totalSum={currentOrder.totalPrice}
                        vatSum={currentOrder.includedTotalVat}
                    />
                </div>
                <div className={styles.productsContainer}>
                    <Headline
                        type={HeadlineType.headline2}
                        title={translator('drinksHeadline')}
                    />
                    <ProductSlider
                        products={recommendedProducts}
                        createAddProductClicked={createAddProductClicked}
                    />
                </div>
                <div className={styles.checkoutButton}>
                    <Button
                        text={I18n.t('order')}
                        onClick={onBasketNextClick}
                        disabled={buttonDisabled}
                        disabledHoverText={buttonDisabledText}
                    />
                </div>
            </div>
        );
    }

    function renderPaymentElement() {
        if (!paymentClientSecret) {
            return null;
        }

        return (
            <PaymentElement />
        );
    }

    function renderPaymentStep() {
        if (
            checkoutStep !== CheckoutSteps.Payment ||
            !paymentClientSecret
        ) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.checkoutContainer,
                    styles.fromLeft,
                )}
            >
                {renderToPaymentCancelButton()}
                <Headline
                    title={translator('paymentHeadline')}
                    type={HeadlineType.headline1}
                />
                <p>
                    {translator('orderPaymentText', {
                        orderNumber: currentOrder.sequentialNumber,
                    })}
                </p>
                <StripeWrapper
                    clientSecret={paymentClientSecret}
                >
                    <StripeCheckoutForm>
                        <div className={styles.checkoutGrid}>
                            {renderPaymentElement()}
                            <div>
                                <PriceCalculation
                                    totalSum={currentOrder.totalPrice}
                                    vatSum={currentOrder.includedTotalVat}
                                />
                            </div>
                        </div>
                        <div className={styles.paymentGrid}>
                            <div>
                                {renderSimpleOrderPositions()}
                            </div>
                            <div>
                                <Button
                                    text={translator('payNowButton')}
                                />
                            </div>
                        </div>
                    </StripeCheckoutForm>
                </StripeWrapper>
            </div>
        );
    }

    return (
        <div
            className={styles.checkoutContent}
        >
            {renderBasketStep()}
            {renderDetailsStep()}
            {renderPaymentStep()}
        </div>
    );
};

CheckoutContent.propTypes = propTypes;

export default CheckoutContent;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import { current }     from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

import OrderSource        from '@constants/OrderSource';
import ProductType        from '@constants/ProductType';
import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    currentOrder: {
        timeSlot:           null,
        servingType:        null,
        contact:            {},
        sessionId:          null,
        orderProducts:      [],
        additionalRequests: [],
    },
    orderSource:  OrderSource.online,
});

const initialOrderProduct = Object.freeze({
    product:          null,
    quantity:         0,
    productAdditions: [],
});

function addNewOrderPosition(state, product) {
    return update(state, {
        currentOrder: {
            orderProducts: {
                $push: [
                    {
                        ...initialOrderProduct,
                        id:       _.uniqueId(),
                        product:  product.iri,
                        type:     product.type,
                        quantity: 1,
                    },
                ],
            },
        },
    });
}

function addToExistingOrderPosition(state, existingOrderProduct) {
    return update(state, {
        currentOrder: {
            orderProducts: {
                [state.currentOrder.orderProducts.indexOf(existingOrderProduct)]: {
                    quantity: {
                        $set: existingOrderProduct.quantity + 1,
                    },
                },
            },
        },
    });
}

const ordersSlice = createSlice({
    name:     'orders',
    initialState,
    reducers: {
        setCurrentOrderTimeSlot:                  (state, action) => {
            return update(state, {
                currentOrder: {
                    timeSlot: {
                        $set: action.payload.timeSlot,
                    },
                },
            });
        },
        setCurrentOrderServingType:               (state, action) => {
            return update(state, {
                currentOrder: {
                    servingType: {
                        $set: action.payload.servingType,
                    },
                },
            });
        },
        setCurrentOrderPaymentType:               (state, action) => {
            return update(state, {
                currentOrder: {
                    paymentType: {
                        $set: action.payload.paymentType,
                    },
                },
            });
        },
        setCurrentOrderAgbAccepted:               (state, action) => {
            return update(state, {
                currentOrder: {
                    agbAccepted: {
                        $set: action.payload.agbAccepted,
                    },
                },
            });
        },
        setCurrentOrderContact:                   (state, action) => {
            return update(state, {
                currentOrder: {
                    contact: {
                        $set: action.payload.contact,
                    },
                },
            });
        },
        setCurrentOrderSessionId:                 (state, action) => {
            return update(state, {
                currentOrder: {
                    sessionId: {
                        $set: action.payload.sessionId,
                    },
                },
            });
        },
        addProductToCurrentOrder:                 _.noop,
        addProductToCurrentOrderSucceeded:        (state, action) => {
            const product = _.get(action, 'payload.product', null);

            if (product.type === ProductType.drink) {
                const existingOrderProduct = _.find(state.currentOrder.orderProducts, {
                    product: product.iri,
                });

                if (existingOrderProduct) {
                    return addToExistingOrderPosition(state, existingOrderProduct);
                }
            }

            return addNewOrderPosition(state, product);
        },
        incrementProductQuantityFromCurrentOrder: (state, action) => {
            const orderProduct         = _.get(action, 'payload.orderProduct', null);
            const existingOrderProduct = _.find(state.currentOrder.orderProducts, {
                id: orderProduct.id,
            });

            if (!existingOrderProduct) {
                return state;
            }

            return addToExistingOrderPosition(state, existingOrderProduct);
        },
        decrementProductQuantityFromCurrentOrder: (state, action) => {
            const orderProduct         = _.get(action, 'payload.orderProduct', null);
            const existingOrderProduct = _.find(state.currentOrder.orderProducts, {
                id: orderProduct.id,
            });

            if (!existingOrderProduct) {
                return state;
            }

            if (existingOrderProduct.quantity === 1) {
                return update(state, {
                    currentOrder: {
                        orderProducts: {
                            $splice: [[state.currentOrder.orderProducts.indexOf(existingOrderProduct), 1]],
                        },
                    },
                });
            }

            return update(state, {
                currentOrder: {
                    orderProducts: {
                        [state.currentOrder.orderProducts.indexOf(existingOrderProduct)]: {
                            quantity: {
                                $set: existingOrderProduct.quantity - 1,
                            },
                        },
                    },
                },
            });
        },
        reserveTimeSlot:                          _.noop,
        reserveTimeSlotFailed:                    (state) => {
            return update(state, {
                currentOrder: {
                    timeSlot: {
                        $set: null,
                    },
                },
            });
        },
        reserveTimeSlotSucceeded:                 (state, action) => {
            return update(state, {
                currentOrder: {
                    timeSlot: {
                        $set: action.payload.timeSlot,
                    },
                },
            });
        },
        clearReservedTimeSlot:                    _.noop,
        clearReservedTimeSlotSucceeded:           (state) => {
            return update(state, {
                currentOrder: {
                    timeSlot: {
                        $set: null,
                    },
                },
            });
        },
        toggleAdditionalRequestForCurrentOrder:   (state, action) => {
            const additionalRequest = _.get(action, 'payload.additionalRequest', null);

            if (_.includes(state.currentOrder.additionalRequests, additionalRequest.iri)) {
                return update(state, {
                    currentOrder: {
                        additionalRequests: {
                            $splice: [[state.currentOrder.additionalRequests.indexOf(additionalRequest.iri), 1]],
                        },
                    },
                });
            }

            return update(state, {
                currentOrder: {
                    additionalRequests: {
                        $push: [additionalRequest.iri],
                    },
                },
            });
        },
        toggleProductAdditionFromOrderProduct:    (state, action) => {
            const productAddition      = _.get(action, 'payload.productAddition', null);
            const orderProduct         = _.get(action, 'payload.orderProduct', null);
            const existingOrderProduct = _.find(state.currentOrder.orderProducts, {
                id: orderProduct.id,
            });

            if (_.includes(orderProduct.productAdditions, productAddition.iri)) {
                return update(state, {
                    currentOrder: {
                        orderProducts: {
                            [state.currentOrder.orderProducts.indexOf(existingOrderProduct)]: {
                                productAdditions: {
                                    $splice: [[orderProduct.productAdditions.indexOf(productAddition.iri), 1]],
                                },
                            },
                        },
                    },
                });
            }

            return update(state, {
                currentOrder: {
                    orderProducts: {
                        [state.currentOrder.orderProducts.indexOf(existingOrderProduct)]: {
                            productAdditions: {
                                $push: [productAddition.iri],
                            },
                        },
                    },
                },
            });
        },
        setCurrentOrderCustomerComment:           (state, action) => {
            const orderPosition        = _.get(action, 'payload.orderPosition', null);
            const comment              = _.get(action, 'payload.comment', null);
            const existingOrderProduct = _.find(state.currentOrder.orderProducts, {
                id: orderPosition.id,
            });

            return update(state, {
                currentOrder: {
                    orderProducts: {
                        [state.currentOrder.orderProducts.indexOf(existingOrderProduct)]: {
                            customerComment: {
                                $set: comment,
                            },
                        },
                    },
                },
            });
        },
        submitOrder:                              LoadingLevelHelper.increaseLoadingEmptyReducer(),
        submitOrderFailed:                        LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        confirmOrder:                             LoadingLevelHelper.increaseLoadingEmptyReducer(),
        confirmOrderSucceeded:                    LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                currentOrder: {
                    confirmed: {
                        $set: true,
                    },
                },
            });
        }),
        confirmOrderFailed:                       LoadingLevelHelper.decreaseLoading((state) => {
            const { sessionId, contact } = state.currentOrder;

            return update(state, {
                currentOrder: {
                    $set: {
                        ...initialState.currentOrder,
                        sessionId,
                        contact,
                    },
                },
            });
        }),
        submitPayment:                            LoadingLevelHelper.increaseLoadingEmptyReducer(),
        submitPaymentFailed:                      LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        submitPaymentCanceled:                    LoadingLevelHelper.decreaseLoading((state) => {
            const { sessionId, contact } = state.currentOrder;

            return update(state, {
                currentOrder: {
                    $set: {
                        ...initialState.currentOrder,
                        sessionId,
                        contact,
                    },
                },
            });
        }),
        submitPaymentSucceeded:                   LoadingLevelHelper.decreaseLoading((state) => {
            const { sessionId, contact } = state.currentOrder;

            return update(state, {
                currentOrder: {
                    $set: {
                        ...initialState.currentOrder,
                        sessionId,
                        contact: {
                            ...contact,
                            comment: '',
                        },
                    },
                },
            });
        }),
        submitOrderSucceeded:                     LoadingLevelHelper.decreaseLoading((state, action) => {
            const { sessionId, contact } = state.currentOrder;
            const order                  = _.get(action, 'payload.order', null);
            const clientSecret           = _.get(order, 'stripePaymentIntentClientSecret', null);
            const sequentialNumber       = _.get(order, 'sequentialNumber', null);
            const id                     = _.get(order, 'id', null);

            if (clientSecret) {
                return update(state, {
                    currentOrder: {
                        clientSecret:     {
                            $set: clientSecret,
                        },
                        sequentialNumber: {
                            $set: sequentialNumber,
                        },
                        id:               {
                            $set: id,
                        },
                    },
                });
            }

            return update(state, {
                currentOrder: {
                    $set: {
                        ...initialState.currentOrder,
                        sessionId,
                        contact: {
                            ...contact,
                            comment: '',
                        },
                    },
                },
            });
        }),
        calculateOrderPrice:                      _.noop,
        calculateOrderPriceSucceeded:             LoadingLevelHelper.decreaseLoading((state, action) => {
            const data                 = _.get(action, 'payload.orderPriceData', null);
            const orderProducts        = _.get(current(state), 'currentOrder.orderProducts', null);
            const updatedOrderProducts = _.map(orderProducts, (orderProduct) => {
                const orderPositionData = _.find(
                    data.orderProducts,
                    {
                        id: orderProduct.id,
                    },
                );

                if (!orderPositionData) {
                    return orderProducts;
                }

                return {
                    ...orderProduct,
                    positionPrice:       orderPositionData.positionPrice,
                    includedPositionVat: orderPositionData.includedPositionVat,
                };
            });

            return update(state, {
                currentOrder: {
                    orderProducts:    {
                        $set: updatedOrderProducts,
                    },
                    totalPrice:       {
                        $set: data.totalPrice,
                    },
                    includedTotalVat: {
                        $set: data.includedTotalVat,
                    },
                },
            });
        }),
        checkIfAddressIsInDeliveryRadius:         _.noop,
        setAddressIsInDeliveryRadius:             (state, action) => {
            return update(state, {
                currentOrder: {
                    isInDeliveryRadius: {
                        $set: action.payload.isInDeliveryRadius,
                    },
                },
            });
        },
        setCurrentOrderPizzaQuantity:             (state, action) => {
            const pizzaQuantity = _.get(action, 'payload.pizzaQuantity', null);

            return update(state, {
                currentOrder: {
                    pizzaQuantity: {
                        $set: pizzaQuantity,
                    },
                },
            });
        },
        checkOrderSource:                         _.noop,
        setOrderSource:                           (state, action) => {
            return update(state, {
                orderSource: {
                    $set: action.payload.orderSource,
                },
            });
        },
    },
});

export const OrdersActions = ordersSlice.actions;

export const OrdersReducer = ordersSlice.reducer;

export default ordersSlice;

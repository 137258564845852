//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';

import PropTypes  from '@components/PropTypes';
import Price      from '@stateless/atomic/Price';
import PriceColor from '@stateless/atomic/Price/PriceColor';
import Spacer     from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = {
    deliveryCost: PropTypes.number,
    totalSum:     PropTypes.number,
    vatSum:       PropTypes.number,
};

const PriceCalculation = ({
    deliveryCost = 0,
    totalSum     = 0,
    vatSum       = 0,
}) => {
    const { t: translator } = useTranslation(null, {
        keyPrefix: 'components.checkout',
    });
    const subTotalSum       = totalSum - vatSum;

    return (
        <div className={styles.priceCalculation}>
            <div className={styles.priceLine}>
                <p>
                    {translator('subTotal')}
                </p>
                <Price
                    value={subTotalSum}
                    color={PriceColor.orange}
                />
            </div>
            <div className={styles.priceLine}>
                <p>
                    {translator('shipment')}
                </p>
                <Price
                    value={deliveryCost}
                    color={PriceColor.orange}
                />
            </div>
            <div className={styles.priceLine}>
                <p>
                    {translator('vatLabel')}
                </p>
                <Price
                    value={vatSum}
                    color={PriceColor.orange}
                />
            </div>
            <Spacer
                dashed={true}
                height={8}
            />
            <div className={styles.priceLine}>
                <p className={styles.big}>
                    {translator('total')}
                </p>
                <Price
                    value={totalSum}
                    color={PriceColor.orange}
                />
            </div>
            <Spacer
                dashed={true}
                height={20}
            />
        </div>
    );
};

PriceCalculation.propTypes = propTypes;

export default PriceCalculation;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import { useMemo } from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import PropTypes         from '@components/PropTypes';
import OpeningHourHelper from '@helper/OpeningHourHelper';

import styles from './styles.module.scss';

const propTypes = {
    isMobile:     PropTypes.bool,
    openingHours: PropTypes.array,
};

const OpeningHours = ({
    openingHours = [],
    isMobile     = false,
}) => {
    const translationBasePath        = 'components.openingHours.';
    const formattedOpeningHours      = useMemo(
        () => OpeningHourHelper.formatOpeningHours(openingHours),
        [openingHours],
    );
    const renderSeparator            = (index, length) => {
        if (
            index < length - 1 &&
            !isMobile
        ) {
            return (
                <div className={styles.separator}>
                    &
                </div>
            );
        }

        return null;
    };
    const renderNoDeliveryLabel      = (deliveryEnabled, noDeliveryLabel) => {
        if (deliveryEnabled) {
            return null;
        }

        let labelText = I18n.t(`${translationBasePath}noDelivery`);

        if (noDeliveryLabel) {
            labelText = noDeliveryLabel;
        }

        return (
            <div
                className={classNames(
                    styles.noDeliveryLabel,
                    {
                        [styles.smallLabel]: isMobile,
                    },
                )}
            >
                {labelText}
            </div>
        );
    };
    const renderOpeningHourEntry     = (openingHourEntry) => {
        const openTime        = openingHourEntry.openTime;
        const closeTime       = openingHourEntry.closeTime;
        const deliveryEnabled = openingHourEntry.deliveryEnabled;
        const noDeliveryLabel = openingHourEntry.noDeliveryLabel;

        return (
            <div>
                <div>
                    {openTime} - {closeTime}
                </div>
                {renderNoDeliveryLabel(
                    deliveryEnabled,
                    noDeliveryLabel,
                )}
            </div>
        );
    };
    const renderWeekdays             = (weekDays) => {
        const translatedWeekDays = weekDays.map((weekDay) => I18n.t(`${translationBasePath}${weekDay}_short`));
        const weekDayString      = translatedWeekDays.join(' & ');

        return (
            <div className={styles.weekdayContainer}>
                {weekDayString}:
            </div>
        );
    };
    const renderOpeningEntries       = (openingHourEntries) => {
        return openingHourEntries.map((openingHourEntry, index) => {
            const keyString = JSON.stringify(openingHourEntry);

            return (
                <div
                    key={keyString}
                    className={styles.openingHourEntry}
                >
                    <div>
                        {renderOpeningHourEntry(openingHourEntry)}
                    </div>
                    {renderSeparator(index, openingHourEntries.length)}
                </div>
            );
        });
    };
    const renderOpeningHourListItems = () => {
        return formattedOpeningHours.map((openingHourItem) => {
            const keyString = JSON.stringify(openingHourItem);

            return (
                <li
                    key={keyString}
                >
                    {renderWeekdays(openingHourItem.weekDays)}
                    <div
                        className={classNames(
                            styles.openingHoursContainer,
                            {
                                [styles.timesInNewLine]: isMobile,
                            },
                        )}
                    >
                        {renderOpeningEntries(openingHourItem.openingHourEntries)}
                    </div>
                </li>
            );
        });
    };

    return (
        <ul className={styles.openingHourList}>
            {renderOpeningHourListItems()}
        </ul>
    );
};

OpeningHours.propTypes = propTypes;

export default OpeningHours;

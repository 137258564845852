//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes from '@components/PropTypes';
import CountDown from '@stateless/atomic/CountDown';

import styles from './styles.module.scss';

const propTypes = {
    onCountDownEnd: PropTypes.func,
    onlyTime:       PropTypes.bool,
    toTimeStamp:    PropTypes.number,
};

const ReservationCountDown = ({
    toTimeStamp    = null,
    onCountDownEnd = _.noop,
    onlyTime       = false,
}) => {
    const { t: translator } = useTranslation(null, {
        keyPrefix: 'components.timeSlotSelector',
    });

    function renderCountDown(days, hours, minutes, seconds) {
        return (
            <span>
                {' '}
                {minutes}
                {':'}
                {seconds}
                {' '}
            </span>
        );
    }

    const countDownComponent = (
        <CountDown
            customRenderer={renderCountDown}
            toTimeStamp={toTimeStamp}
            onCountDownEnd={onCountDownEnd}
        />
    );

    if (onlyTime) {
        return countDownComponent;
    }

    return (
        <p className={styles.countDownText}>
            {translator('countDownReservationPreText')}
            <CountDown
                customRenderer={renderCountDown}
                toTimeStamp={toTimeStamp}
                onCountDownEnd={onCountDownEnd}
            />
            {translator('countDownReservationPostText')}
        </p>
    );
};

ReservationCountDown.propTypes = propTypes;

export default ReservationCountDown;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';

import PlateEmpty                 from '@assets/images/plateEmpty.png';
import ContentWrapper             from '@stateless/atomic/ContentWrapper';
import PageTitle                  from '@stateless/composed/PageTitle';
import { selectActiveAppSetting } from '@store/selectors/appSettings';

import styles from './styles.module.scss';

const DeactivatedScreen = () => {
    const appSetting      = useSelector(selectActiveAppSetting);
    const deactivatedText = appSetting?.bookingDisabledText ?? I18n.t('deactivatedDefaultText');

    return (
        <>
            <PageTitle title={I18n.t('deactivated')} />
            <ContentWrapper>
                <div className={styles.deactivatedScreen}>
                    <h1>
                        {I18n.t('deactivatedHeadline')}
                    </h1>
                    <img
                        src={PlateEmpty}
                        alt="empty plate picture"
                    />
                    <h2>
                        {deactivatedText}
                    </h2>
                </div>
            </ContentWrapper>
        </>
    );
};

export default DeactivatedScreen;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useMemo }  from 'react';
import { useState } from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes     from '@components/PropTypes';
import ProductType   from '@constants/ProductType';
import ProductHelper from '@helper/ProductHelper';
import Button        from '@stateless/atomic/Button';
import ButtonColor   from '@stateless/atomic/Button/ButtonColor';
import ButtonType    from '@stateless/atomic/Button/ButtonType';
import CheckBox      from '@stateless/atomic/CheckBox';
import CheckBoxColor from '@stateless/atomic/CheckBox/CheckBoxColor';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';
import ProductItem   from '@stateless/atomic/ProductItem';
import Spacer        from '@stateless/atomic/Spacer';

import styles from './styles.module.scss';

const propTypes = {
    createOnAddToCartClicked:   PropTypes.func,
    createOnProductInfoClicked: PropTypes.func,
    products:                   PropTypes.array,
};

const ProductGrid = ({
    products                   = [],
    createOnProductInfoClicked = _.noop,
    createOnAddToCartClicked   = _.noop,
}) => {
    const [activeProductType, setActiveProductType] = useState(ProductType.pizza);
    const [onlyVegan, setOnlyVegan]                 = useState(false);
    const [onlyVegetarian, setOnlyVegetarian]       = useState(false);
    const pizzaButtonColor                          = useMemo(() => {
        return activeProductType === ProductType.pizza ?
            ButtonColor.black :
            ButtonColor.gray;
    }, [activeProductType]);
    const drinksButtonColor                         = useMemo(() => {
        return activeProductType === ProductType.drink ?
            ButtonColor.black :
            ButtonColor.gray;
    }, [activeProductType]);
    const filteredProducts                          = useMemo(
        () => {
            return ProductHelper.filterProducts(
                products,
                activeProductType,
                onlyVegan,
                onlyVegetarian,
            );
        },
        [
            onlyVegan,
            onlyVegetarian,
            activeProductType,
            products,
        ],
    );
    const filterItems                               = useMemo(
        () => [
            {
                text:    I18n.t('veganLabel'),
                checked: onlyVegan,
                onClick: () => setOnlyVegan((current) => !current),
            },
            {
                text:    I18n.t('vegetarianLabel'),
                checked: onlyVegetarian,
                onClick: () => setOnlyVegetarian((current) => !current),
            },
        ],
        [
            onlyVegan,
            onlyVegetarian,
            setOnlyVegetarian,
            setOnlyVegan,
        ],
    );

    function renderProducts() {
        return filteredProducts.map((product) => {
            const onProductInfoClicked = createOnProductInfoClicked(product);
            const onAddToCartClicked   = createOnAddToCartClicked(product);

            return (
                <ProductItem
                    key={product.iri}
                    product={product}
                    onProductInfoClicked={onProductInfoClicked}
                    addProductToCartClicked={onAddToCartClicked}
                />
            );
        });
    }

    function handlePizzaClicked() {
        setActiveProductType(ProductType.pizza);
    }

    function handleDrinksClicked() {
        setActiveProductType(ProductType.drink);
        setOnlyVegan(false);
        setOnlyVegetarian(false);
    }

    function renderFilter() {
        if (activeProductType === ProductType.drink) {
            return null;
        }

        return filterItems.map((filterItem) => {
            return (
                <div
                    key={filterItem.text}
                    className={styles.veganFilter}
                >
                    <CheckBox
                        color={CheckBoxColor.green}
                        text={filterItem.text}
                        checked={filterItem.checked}
                        onClick={filterItem.onClick}
                    />
                    <Icon iconType={IconType.vegan} />
                </div>
            );
        });
    }

    return (
        <div className={styles.productGridContainer}>
            <div className={styles.productsActionRow}>
                <div className={styles.decisionButtonWrapper}>
                    <Button
                        type={ButtonType.pill}
                        text={I18n.t('pizza')}
                        color={pizzaButtonColor}
                        iconLeft={IconType.pizza}
                        onClick={handlePizzaClicked}
                    />
                    <Button
                        type={ButtonType.pill}
                        text={I18n.t('drinks')}
                        color={drinksButtonColor}
                        iconLeft={IconType.drink}
                        onClick={handleDrinksClicked}
                    />
                </div>
                <div>
                    {renderFilter()}
                </div>
            </div>
            <Spacer height={80} />
            <div className={styles.productGrid}>
                {renderProducts()}
            </div>
        </div>

    );
};

ProductGrid.propTypes = propTypes;

export default ProductGrid;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

const initialState = Object.freeze({
    deliveryExceptions: [],
});

const deliveryExceptionsSlice = createSlice({
    name:     'deliveryExceptions',
    initialState,
    reducers: {
        fetchDeliveryExceptions:          _.noop,
        fetchDeliveryExceptionsSucceeded: (state, action) => {
            return update(state, {
                deliveryExceptions: {
                    $set: _.get(action, 'payload.deliveryExceptions', []),
                },
            });
        },
        fetchDeliveryExceptionsFailed:    _.noop,
    },
});

export const DeliveryExceptionsActions = deliveryExceptionsSlice.actions;

export const DeliveryExceptionsReducer = deliveryExceptionsSlice.reducer;

export default deliveryExceptionsSlice;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
    selected: PropTypes.bool,
    text:     PropTypes.string,
    tooSmall: PropTypes.bool,
};

const TimeSlotItem = ({
    onClick  = _.noop,
    selected = false,
    text     = '',
    disabled = false,
    tooSmall = false,
}) => {
    const [pressed, setPressed] = React.useState(false);

    function onMouseDown(event) {
        setPressed(true);
    }

    function onMouseUp(event) {
        setPressed(false);
    }

    function renderTimeSlot() {
        if (disabled) {
            return (
                <div className={styles.disabledTimeSlot}>
                    <div />
                </div>
            );
        }

        return (
            <div
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onClick={onClick}
                className={classNames(
                    styles.timeSlotItem,
                    {
                        [styles.selected]: selected,
                        [styles.pressed]:  pressed,
                        [styles.tooSmall]: tooSmall,
                    },
                )}
            >
                {text}
            </div>
        );
    }

    return renderTimeSlot();
};

TimeSlotItem.propTypes = propTypes;

export default TimeSlotItem;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory }  from 'react-router';

import PropTypes                           from '@components/PropTypes';
import Overlay                             from '@constants/Overlay';
import Routes                              from '@constants/Routes';
import Notification                        from '@helper/Notification';
import withScreenSize                      from '@hoc/ScreenSizeProvider';
import { OverlayActions }                  from '@slices/overlay';
import StatelessHeader                     from '@stateless/composed/Header';
import { selectCurrentOrderOrderProducts } from '@store/selectors/orders';
import { selectCurrentOrderTimeSlot }      from '@store/selectors/orders';
import { selectCurrentRestaurant }         from '@store/selectors/restaurants';

const propTypes = {
    isDesktop: PropTypes.bool,
};

const Header = ({
    isDesktop = false,
}) => {
    const dispatch                  = useDispatch();
    const history                   = useHistory();
    const currentRestaurant         = useSelector(selectCurrentRestaurant);
    const currentOrderOrderProducts = useSelector(selectCurrentOrderOrderProducts);
    const selectedTimeSlot          = useSelector(selectCurrentOrderTimeSlot);

    function onCheckoutClicked() {
        if (!selectedTimeSlot) {
            Notification.info('firstSelectTimeSlot');

            return;
        }

        if (currentOrderOrderProducts.length === 0) {
            Notification.info('pleaseSelectProducts');

            return;
        }

        if (!isDesktop) {
            history.push(Routes.checkout);

            return;
        }

        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.checkout,
        }));
    }

    function onMenuClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.mobileRestaurantInfo,
        }));
    }

    return (
        <StatelessHeader
            onCheckoutClicked={onCheckoutClicked}
            onMenuClicked={onMenuClicked}
            restaurant={currentRestaurant}
        />
    );
};

Header.propTypes = propTypes;

export default withScreenSize(Header);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useMemo } from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import ProductType                    from '@constants/ProductType';
import { selectCurrentOrder }         from '@store/selectors/orders';
import { selectCurrentOrderTimeSlot } from '@store/selectors/orders';

const useOrderCapacity = () => {
    const currentOrder     = useSelector(selectCurrentOrder);
    const selectedTimeSlot = useSelector(selectCurrentOrderTimeSlot);
    const numberOfPizzas   = useMemo(() => _.sumBy(currentOrder?.orderProducts, (orderProduct) => {
        if (orderProduct.type === ProductType.pizza) {
            return orderProduct.quantity;
        }

        return 0;
    }), [currentOrder]);
    const availableSlots   = useMemo(
        () => selectedTimeSlot?.availableSlots,
        [selectedTimeSlot],
    );
    const freePizzaSlots   = useMemo(() => {
        return availableSlots - numberOfPizzas;
    }, [availableSlots, numberOfPizzas]);

    return {
        numberOfPizzas,
        freePizzaSlots,
        availableSlots,
        selectedTimeSlot,
    };
};

export default useOrderCapacity;

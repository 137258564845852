//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useRef }    from 'react';
import { useEffect } from 'react';

function usePrevious(value) {
    const prevRef = useRef();

    useEffect(() => {
        prevRef.current = value;
    }, [value]);

    return prevRef.current;
}

export default usePrevious;

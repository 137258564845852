//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch }    from 'react-redux';
import { useSelector }    from 'react-redux';

import PropTypes                                from '@components/PropTypes';
import { OrdersActions }                        from '@slices/orders';
import TextInputType                            from '@stateless/atomic/TextInput/TextInputType';
import StatelessAddressForm                     from '@stateless/composed/AddressForm';
import { selectCurrentOrderContact }            from '@store/selectors/orders';
import { selectCurrentOrderServingType }        from '@store/selectors/orders';
import { selectCurrentOrderIsInDeliveryRadius } from '@store/selectors/orders';

const propTypes = {
    isCheckout: PropTypes.bool,
};

const AddressForm = ({
    isCheckout = false,
}) => {
    const dispatch                                          = useDispatch();
    const { t: translator }                                 = useTranslation(null, {
        keyPrefix: 'components.addressForm',
    });
    const currentOrderContact                               = useSelector(selectCurrentOrderContact);
    const currentOrderServingType                           = useSelector(selectCurrentOrderServingType);
    const isInDeliveryRadius                                = useSelector(selectCurrentOrderIsInDeliveryRadius);
    const [deliveryRadiusTimeout, setDeliveryRadiusTimeout] = useState(null);

    function handleAddressCheck(contact) {
        if (deliveryRadiusTimeout) {
            clearTimeout(deliveryRadiusTimeout);
        }

        const { street, houseNumber, postalCode, city } = contact;

        if (
            !street ||
            !houseNumber ||
            !postalCode ||
            !city
        ) {
            OrdersActions.setAddressIsInDeliveryRadius({
                isInDeliveryRadius: null,
            });

            return;
        }

        setDeliveryRadiusTimeout(setTimeout(() => {
            dispatch(OrdersActions.checkIfAddressIsInDeliveryRadius({
                contact,
            }));
        }, 1000));
    }

    useEffect(
        () => {
            handleAddressCheck(currentOrderContact);
        },
        [],
    );

    function handleContactChanged(contactKey) {
        return (event) => {
            const contact       = {
                ...currentOrderContact,
            };
            contact[contactKey] = event.target.value;

            dispatch(OrdersActions.setCurrentOrderContact({
                contact,
            }));
            handleAddressCheck(contact);
        };
    }

    function createField(labelKey, name, required, isDeliveryOnly = false, isCheckoutOnly = false, inputType = null) {
        return {
            label:    translator(labelKey),
            name,
            onChange: handleContactChanged(name),
            value:    currentOrderContact[name],
            ...(
                isDeliveryOnly && {
                    onlyDelivery: true,
                }
            ),
            ...(
                inputType && {
                    type: inputType,
                }
            ),
            ...(
                isCheckoutOnly && {
                    onlyCheckout: true,
                }
            ),
            required,
        };
    }

    const fields = [
        // @formatter:off
        createField('firstName',   'firstName',   true),
        createField('lastName',    'lastName',    true),
        createField('street',      'street',      true,  true),
        createField('houseNumber', 'houseNumber', true,  true),
        createField('postalCode',  'postalCode',  true,  true),
        createField('city',        'city',        true,  true),
        createField('phone',       'phoneNumber', true,  false, false, TextInputType.tel),
        createField('email',       'email',       true,  false, false, TextInputType.email),
        createField('comment',     'comment',     false, false, true,  TextInputType.textarea),
        // @formatter:on
    ];

    return (
        <StatelessAddressForm
            fields={fields}
            servingType={currentOrderServingType}
            isInDeliveryRadius={isInDeliveryRadius}
            isCheckout={isCheckout}
        />
    );
};

AddressForm.propTypes = propTypes;

export default AddressForm;

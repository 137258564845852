//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { replace } from 'connected-react-router';
import _           from 'lodash';
import { put }     from 'redux-saga/effects';

function* removeUnsecureQueryParameters(unsecureQueryParameters = [
    'token',
    'userId',
    'username',
]) {
    let anyUnsecureQueryParameters = false;
    const search                   = _.get(window, 'location.search');
    const query                    = new URLSearchParams(search);

    for (const unsecureQueryParameter of unsecureQueryParameters) {
        if (query.has(unsecureQueryParameter)) {
            anyUnsecureQueryParameters = true;

            query.delete(unsecureQueryParameter);
        }
    }

    if (anyUnsecureQueryParameters) {
        yield put(replace({
            search: query.toString(),
        }));
    }
}

export default {
    removeUnsecureQueryParameters,
};

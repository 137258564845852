//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
    href:     PropTypes.string,
    text:     PropTypes.string,
};

const FooterListItem = ({
    children = null,
    href     = '',
    text     = '',
}) => {
    const renderLinkContent = () => {
        if (children) {
            return children;
        }

        return text;
    };

    return (
        <a
            href={href}
            target={'_blank'}
            className={styles.footerListItem}
            rel="noreferrer"
        >
            {renderLinkContent()}
        </a>
    );
};

FooterListItem.propTypes = propTypes;

export default FooterListItem;

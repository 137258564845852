//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

const withWindowScrolled = (WrappedComponent) => {
    const Component = (props) => {
        const [scrolled, setScrolled] = useState(false);

        function handleScroll(event) {
            const { scrollY } = event.currentTarget;

            setScrolled(scrollY > 0);
        }

        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        return (
            <WrappedComponent
                {...props}
                scrolled={scrolled}
            />
        );
    };

    return Component;
};

export default withWindowScrolled;

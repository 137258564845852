//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames         from 'classnames';
import { useTranslation } from 'react-i18next';

import PropTypes            from '@components/PropTypes';
import DateHelper           from '@helper/Date';
import useOrderCapacity     from '@hooks/orderCapacity';
import ReservationCountDown from '@stateless/atomic/ReservationCountDown';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.node,
    expanded: PropTypes.bool,
    show:     PropTypes.bool,
};

const OrderInfoButton = ({
    children = null,
    show     = true,
    expanded = true,
}) => {
    const { t: translator } = useTranslation(null, {
        keyPrefix: 'components.orderInfoButton',
    });
    const {
        availableSlots,
        selectedTimeSlot,
        freePizzaSlots,
    }                 = useOrderCapacity();

    function renderNumberOfFreePizzas() {
        if (!availableSlots) {
            return null;
        }

        let text = translator(
            'availableSlots',
            {
                availableSlots: freePizzaSlots,
            },
        );

        if (freePizzaSlots === 0) {
            text = translator('noAvailableSlots');
        } else if (freePizzaSlots < 0) {
            text = translator('tooManyPizzas');
        }

        return (
            <p>
                {text}
            </p>
        );
    }

    function renderCountDown() {
        const reservedTillDateString = selectedTimeSlot?.reservedTill;

        if (!reservedTillDateString) {
            return null;
        }

        const reservedTill = DateHelper.dateStringToTimestamp(reservedTillDateString);

        return (
            <p>
                {translator('timeSlotPrefix')}
                <ReservationCountDown
                    toTimeStamp={reservedTill}
                    onlyTime={true}
                />
                {translator('timeSlotSuffix')}
            </p>
        );
    }

    if (!show) {
        return null;
    }

    return (
        <div className={styles.orderInfoCheckoutButton}>
            <div
                className={
                    classNames(
                        styles.orderInfoContainer,
                        {
                            [styles.expanded]: expanded,
                        },
                    )
                }
            >
                {renderNumberOfFreePizzas()}
                {renderCountDown()}
            </div>
            {children}
        </div>
    );
};

OrderInfoButton.propTypes = propTypes;

export default OrderInfoButton;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import { current }     from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';
import moment          from 'moment';

const initialState = Object.freeze({
    groupedTimeSlots: {},
});

function setTimeSlots(newState, timeSlots) {
    return update(newState, {
        timeSlots: {
            $set: timeSlots,
        },
    });
}

function createTimeSlotGroup(newState, date, timeSlotsInGroup) {
    return update(newState, {
        groupedTimeSlots: {
            $merge: {
                [date]: _.map(timeSlotsInGroup, (timeSlot) => timeSlot.iri),
            },
        },
    });
}

function addToTimeSlotGroup(newState, date, timeSlot) {
    return update(newState, {
        groupedTimeSlots: {
            [date]: {
                $push: [timeSlot.iri],
            },
        },
    });
}

const timeSlotsSlice = createSlice({
    name:     'timeSlots',
    initialState,
    reducers: {
        resetTimeSlots:          (state, action) => {
            return initialState;
        },
        fetchTimeSlots:          _.noop,
        fetchTimeSlotsFailed:    _.noop,
        fetchTimeSlotsSucceeded: (state, action) => {
            const timeSlots        = action.payload.timeSlots;
            const groupedTimeSlots = _.groupBy(timeSlots, (timeSlot) => {
                const startDateTime = moment(timeSlot.startDateTime);

                startDateTime
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0)
                    .set('millisecond', 0);

                return startDateTime.toISOString();
            });
            let newState           = current(state);
            newState               = setTimeSlots(newState, timeSlots);

            _.forEach(groupedTimeSlots, (timeSlotsInGroup, date) => {
                const existingTimeSlotGroup = _.get(newState, ['groupedTimeSlots', date], null);

                if (!existingTimeSlotGroup) {
                    newState = createTimeSlotGroup(newState, date, timeSlotsInGroup);

                    return;
                }

                _.forEach(timeSlotsInGroup, (timeSlot) => {
                    const existingTimeSlot = _.find(existingTimeSlotGroup, (existingIri) => {
                        return existingIri === timeSlot.iri;
                    });

                    if (!existingTimeSlot) {
                        newState = addToTimeSlotGroup(newState, date, timeSlot);
                    }
                });
            });

            return newState;
        },
    },
});

export const TimeSlotsActions = timeSlotsSlice.actions;

export const TimeSlotsReducer = timeSlotsSlice.reducer;

export default timeSlotsSlice;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import _               from 'lodash';

const initialState = Object.freeze({
    activeAppSetting: null,
});

const appSettingsSlice = createSlice({
    name:     'appSettings',
    initialState,
    reducers: {
        fetchAppSettings:          _.noop,
        fetchAppSettingsFailed:    _.noop,
        fetchAppSettingsSucceeded: _.noop,
        setActiveAppSetting:       (state, action) => {
            return update(state, {
                activeAppSetting: {
                    $set: action.payload,
                },
            });
        },
        fetchSettingsInInterval:   _.noop,
    },
});

export const AppSettingsActions = appSettingsSlice.actions;

export const AppSettingsReducer = appSettingsSlice.reducer;

export default appSettingsSlice;

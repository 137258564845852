//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    API_CONFIGURATION_URL:      'api/configuration',
    API_TOKENS:                 'api/tokens',
    API_PRODUCTS_URL:           'api/products',
    API_APP_SETTINGS:           'api/app-settings',
    API_ALLERGENS:              'api/allergens',
    API_DELIVERY_EXCEPTION:     'api/delivery-exceptions',
    API_ADDITIONAL_REQUESTS:    'api/additional-requests',
    API_PRODUCT_ADDITIONS:      'api/product-additions',
    API_TIME_SLOTS:             'api/time-slots',
    API_TIME_SLOTS_RESERVE:     (iri) => `${iri}/reserve`,
    API_TIME_SLOTS_RELEASE:     (iri) => `${iri}/release`,
    API_RESTAURANTS:            'api/restaurants',
    API_ORDERS:                 'api/orders',
    API_ORDERS_CONFIRM:         (id) => `api/orders/${id}/confirm`,
    API_ORDERS_PRICE:           'api/orders/price',
    API_ADDRESS_DISTANCE_CHECK: 'api/address-distance-check',
});

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Splide }      from '@splidejs/react-splide';
import { SplideSlide } from '@splidejs/react-splide';
import { SplideTrack } from '@splidejs/react-splide';
import classNames      from 'classnames';
import _               from 'lodash';

import PropTypes  from '@components/PropTypes';
import ArrowColor from '@stateless/atomic/Carousel/ArrowColor';
import ArrowSize  from '@stateless/atomic/Carousel/ArrowSize';
import Icon       from '@stateless/atomic/Icon';
import IconType   from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

// eslint-disable-next-line rulesdir/format-import-linter
import '@splidejs/react-splide/css';

const propTypes = {
    arrowColor: PropTypes.arrowColor,
    arrowIcon:  PropTypes.iconType,
    arrowSize:  PropTypes.arrowSize,
    children:   PropTypes.children,
    className:  PropTypes.string,
    options:    PropTypes.object,
};

const Carousel = ({
    children   = [],
    options    = null,
    arrowIcon  = IconType.chevronRight,
    arrowColor = ArrowColor.white,
    arrowSize  = ArrowSize.default,
    className  = '',
}) => {
    function renderSlides() {
        return _.map(
            children,
            (child) => {
                return (
                    <SplideSlide key={_.uniqueId()}>
                        {child}
                    </SplideSlide>
                );
            },
        );
    }

    return (
        <Splide
            options={options}
            className={classNames(styles.carouselWrapper, className)}
            hasTrack={false}
        >
            <div className="splide__arrows">
                <button
                    className={classNames(
                        'splide__arrow',
                        'splide__arrow--prev',
                        styles.arrow,
                        styles.left,
                        {
                            [styles.small]:      arrowSize === ArrowSize.small,
                            [styles.arrowBlack]: arrowColor === ArrowColor.black,
                            [styles.arrowWhite]: arrowColor === ArrowColor.white,
                        },
                    )}
                >
                    <Icon
                        iconType={arrowIcon}
                    />
                </button>
                <button
                    className={classNames(
                        'splide__arrow',
                        'splide__arrow--next',
                        styles.arrow,
                        styles.right,
                        {
                            [styles.small]:      arrowSize === ArrowSize.small,
                            [styles.arrowBlack]: arrowColor === ArrowColor.black,
                            [styles.arrowWhite]: arrowColor === ArrowColor.white,
                        },
                    )}
                >
                    <Icon
                        iconType={arrowIcon}
                    />
                </button>
            </div>
            <SplideTrack>
                {renderSlides()}
            </SplideTrack>
        </Splide>
    );
};

Carousel.propTypes = propTypes;

export default Carousel;

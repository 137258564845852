//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useRef }    from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import AddressForm                           from '@connected/AddressForm';
import PizzaQuantitySelector                 from '@connected/PizzaQuantitySelector';
import ProductGrid                           from '@connected/ProductGrid';
import ServingTypeButton                     from '@connected/ServingTypeButton';
import Api                                   from '@constants/Api';
import OrderSource                           from '@constants/OrderSource';
import Overlay                               from '@constants/Overlay';
import ServingType                           from '@constants/ServingType';
import usePrevious                           from '@helper/CustomHooks';
import DateHelper                            from '@helper/Date';
import DateTime                              from '@helper/DateTime';
import TimeSlotHelper                        from '@helper/TimeSlotHelper';
import { OverlayActions }                    from '@slices/overlay';
import Button                                from '@stateless/atomic/Button';
import ButtonColor                           from '@stateless/atomic/Button/ButtonColor';
import ButtonType                            from '@stateless/atomic/Button/ButtonType';
import CircleIcon                            from '@stateless/atomic/CircleIcon';
import ContentWrapper                        from '@stateless/atomic/ContentWrapper';
import Headline                              from '@stateless/atomic/Headline';
import HeadlineType                          from '@stateless/atomic/Headline/HeadlineType';
import IconType                              from '@stateless/atomic/Icon/IconType';
import ReservationCountDown                  from '@stateless/atomic/ReservationCountDown';
import Spacer                                from '@stateless/atomic/Spacer';
import PageTitle                             from '@stateless/composed/PageTitle';
import TimeSlotSelector                      from '@stateless/composed/TimeSlotSelector';
import { selectMinimumOrderValue }           from '@store/selectors/appSettings';
import { selectActiveAppSetting }            from '@store/selectors/appSettings';
import { selectDeliveryExceptionByTimeSlot } from '@store/selectors/deliveryExceptions';
import { selectCurrentOrderTimeSlot }        from '@store/selectors/orders';
import { selectCurrentOrderServingType }     from '@store/selectors/orders';
import { selectOrderSource }                 from '@store/selectors/orders';
import { selectGroupedTimeSlots }            from '@store/selectors/timeSlots';

import styles from './styles.module.scss';

const Home = () => {
    const dispatch                    = useDispatch();
    const selectedTimeSlot            = useSelector(selectCurrentOrderTimeSlot);
    const selectedServingType         = useSelector(selectCurrentOrderServingType);
    const [showPizzas, setShowPizzas] = React.useState(false);
    const servingStepRef              = useRef(null);
    const productStepRef              = useRef(null);
    const activeAppSetting            = useSelector(selectActiveAppSetting);
    const addressStepRef              = useRef(null);
    const prevTimeSlots               = usePrevious(selectedTimeSlot);
    const prevServingType             = usePrevious(selectedServingType);
    const deliveryException           = useSelector(selectDeliveryExceptionByTimeSlot(selectedTimeSlot));
    const timeSlotGroups              = useSelector(selectGroupedTimeSlots);
    const minimumOrderValue           = useSelector(selectMinimumOrderValue);
    const orderSource                 = useSelector(selectOrderSource);

    function scrollToStep(stepRef) {
        setTimeout(() => {
            if (!stepRef.current) {
                return;
            }

            stepRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        });
    }

    useEffect(
        () => {
            if (prevTimeSlots === null) {
                scrollToStep(servingStepRef);
            }

            if (prevServingType === null) {
                if (selectedServingType === ServingType.delivery) {
                    scrollToStep(addressStepRef);
                } else {
                    scrollToStep(productStepRef);
                }
            }
        },
        [
            selectedTimeSlot,
            selectedServingType,
        ],
    );

    function handleShowAllClicked() {
        setShowPizzas(true);
        scrollToStep(productStepRef);
    }

    function renderPizzaQuantityStep() {
        return (
            <div className={styles.orderStep}>
                <Spacer height={40} />
                <Headline
                    title={I18n.t('pizzaQuantityHeadline')}
                    type={HeadlineType.headline2}
                />
                <Spacer height={50} />
                <PizzaQuantitySelector />
                <Spacer height={50} />
                <Button
                    type={ButtonType.pill}
                    color={ButtonColor.black}
                    text={I18n.t('tooPizzasButtonText')}
                    onClick={handleShowAllClicked}
                />
            </div>
        );
    }

    function renderCountDown() {
        const reservedTillDateString = selectedTimeSlot?.reservedTill;

        if (!reservedTillDateString) {
            return null;
        }

        const reservedTill = DateHelper.dateStringToTimestamp(reservedTillDateString);

        return (
            <ReservationCountDown toTimeStamp={reservedTill} />
        );
    }

    function renderSoldOutText() {
        const soldOutTill = activeAppSetting?.soldOutTill;
        const soldOut     = DateTime.isOnOrBeforeDay(soldOutTill, new DateTime());

        if (!soldOut) {
            return null;
        }

        const soldOutTillToday = DateTime.isToday(soldOutTill);
        const soldOutText      = soldOutTillToday
            ? I18n.t('soldOutTodayText')
            : I18n.t('soldOutText', {
                date: TimeSlotHelper.getDateString(soldOutTill),
            });

        return (
            <p>
                {soldOutText}
            </p>
        );
    }

    function renderTimeSlotStep() {
        return (
            <>
                <Spacer height={80} />
                <TimeSlotSelector
                    groupedTimeSlots={timeSlotGroups}
                />
                <Spacer height={20} />
                {renderCountDown()}
                {renderSoldOutText()}
            </>
        );
    }

    function openInfoOverlayClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.info,
        }));
    }

    function renderProductStep() {
        return (
            <div
                className={styles.orderStep}
                ref={productStepRef}
            >
                <Spacer height={80} />
                <Headline
                    title={I18n.t('productsHeadline')}
                    type={HeadlineType.headline2}
                />
                <Spacer height={50} />
                <ProductGrid />
            </div>
        );
    }

    function renderDeliveryExceptionHind() {
        if (!deliveryException) {
            return null;
        }

        return (
            <>
                <Spacer height={20} />
                <p>
                    * {deliveryException.reason}
                </p>
            </>
        );
    }

    function renderMinimumOrderQuantityHint() {
        if (selectedServingType !== ServingType.delivery) {
            return null;
        }

        return (
            <>
                <Spacer height={20} />
                <p>
                    {I18n.t('minimumOrderValueLabel', {
                        minimumOrderValue,
                    })}
                </p>
            </>
        );
    }

    function renderServingStep() {
        return (
            <div
                className={styles.orderStep}
                ref={servingStepRef}
            >
                <Spacer height={80} />
                <Headline
                    title={I18n.t('deliveryHeadline')}
                    type={HeadlineType.headline2}
                />
                <Spacer height={50} />
                <div className={styles.decisionButtonWrapper}>
                    <ServingTypeButton servingType={ServingType.delivery} />
                    <ServingTypeButton servingType={ServingType.clickAndCollect} />
                </div>
                {renderMinimumOrderQuantityHint()}
                {renderDeliveryExceptionHind()}
            </div>
        );
    }

    function renderAddressStep() {
        return (
            <div
                className={styles.orderStep}
                ref={addressStepRef}
            >
                <Spacer height={80} />
                <Headline
                    title={I18n.t('addressHeadline')}
                    type={HeadlineType.headline2}
                />
                <Spacer height={50} />
                <AddressForm />
            </div>
        );
    }

    function renderOrderSteps() {
        const steps = [
            {
                id:        'pizza-quantity-step',
                condition: true,
                render:    renderPizzaQuantityStep,
            },
            {
                id:        'time-slot-step',
                condition: true,
                render:    renderTimeSlotStep,
            },
            {
                id:        'serving-step',
                condition: selectedTimeSlot,
                render:    renderServingStep,
            },
            {
                id:        'address-step',
                condition: (
                    selectedTimeSlot &&
                               selectedServingType === ServingType.delivery
                ),
                render:    renderAddressStep,
            },
            {
                id:        'product-step',
                condition: (
                    showPizzas || (
                        selectedTimeSlot &&
                                   selectedServingType
                    )
                ),
                render:    renderProductStep,
            },
        ];

        return steps.map((step) => {
            if (!step.condition) {
                return null;
            }

            return (
                <React.Fragment key={step.id}>
                    {step.render()}
                </React.Fragment>
            );
        });
    }

    function renderBackToKitchenAppButton() {
        if (orderSource === OrderSource.online) {
            return null;
        }

        return (
            <div className={styles.backToKitchenButtonWrapper}>
                <a href={Api.getKitchenUrl()}>
                    <Button
                        color={ButtonColor.black}
                        type={ButtonType.pill}
                        iconLeft={IconType.arrowLeft}
                        text={I18n.t('backToCheckoutButtonText')}
                    />
                </a>
            </div>
        );
    }

    function renderInfoBanner() {
        const infoText = activeAppSetting?.infoText;

        if (!infoText) {
            return null;
        }

        return (
            <>
                <Spacer height={20} />
                <div className={styles.infoBanner}>
                    {infoText}
                </div>
            </>
        );
    }

    return (
        <>
            <PageTitle title={I18n.t('home')} />
            <ContentWrapper>
                {renderBackToKitchenAppButton()}
                <div className={styles.homeContent}>
                    <div className={styles.headlineWrapper}>
                        <Headline
                            title={I18n.t('homeHeadline')}
                            type={HeadlineType.headline1}
                        />
                        <CircleIcon
                            iconType={IconType.info}
                            onClick={openInfoOverlayClicked}
                        />
                    </div>
                    {renderInfoBanner()}
                    {renderOrderSteps()}
                </div>
            </ContentWrapper>
        </>
    );
};

export default Home;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n from 'i18next';

import CheckoutContent from '@connected/CheckoutContent';
import ContentWrapper  from '@stateless/atomic/ContentWrapper';
import PageTitle       from '@stateless/composed/PageTitle';

const CheckoutScreen = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return (
        <>
            <PageTitle title={I18n.t('checkout')} />
            <ContentWrapper>
                <CheckoutContent />
            </ContentWrapper>
        </>
    );
};

export default CheckoutScreen;

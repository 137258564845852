//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _       from 'lodash';
import { put } from 'redux-saga/effects';

import Overlay                            from '@constants/Overlay';
import SagaStateHelper                    from '@helper/SagaStateHelper';
import { OrdersActions }                  from '@slices/orders';
import { selectCurrentOrderClientSecret } from '@store/selectors/orders';

function* closeOverlay(action) {
    const overlay                  = _.get(action, 'payload.overlay', null);
    const currentOrderClientSecret = yield SagaStateHelper.selectBySelector(selectCurrentOrderClientSecret);

    if (
        overlay === Overlay.checkout &&
        currentOrderClientSecret
    ) {
        yield put(OrdersActions.submitPaymentCanceled());
    }
}

export default {
    closeOverlay,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';

import StatelessFooter             from '@stateless/atomic/Footer';
import { selectCurrentRestaurant } from '@store/selectors/restaurants';

const propTypes = {};

const Footer = () => {
    const restaurant = useSelector(selectCurrentRestaurant);

    return (
        <StatelessFooter
            restaurant={restaurant}
        />
    );
};

Footer.propTypes = propTypes;

export default Footer;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }         from 'connected-react-router';
import _                from 'lodash';
import { put }          from 'redux-saga/effects';
import { call }         from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import * as Api                            from '@api';
import OrderAppSettings                    from '@constants/OrderAppSettings';
import OrderSource                         from '@constants/OrderSource';
import Overlay                             from '@constants/Overlay';
import PaymentType                         from '@constants/PaymentType';
import ProductType                         from '@constants/ProductType';
import Routes                              from '@constants/Routes';
import ServingType                         from '@constants/ServingType';
import Navigation                          from '@helper/Navigation';
import Notification                        from '@helper/Notification';
import SagaStateHelper                     from '@helper/SagaStateHelper';
import { LoadingActions }                  from '@slices/loading';
import { NavigationActions }               from '@slices/navigation';
import { OrdersActions }                   from '@slices/orders';
import { OverlayActions }                  from '@slices/overlay';
import { TimeSlotsActions }                from '@slices/timeSlots';
import { selectCurrentOrderSessionId }     from '@store/selectors/orders';
import { selectCurrentOrderServingType }   from '@store/selectors/orders';
import { selectCurrentOrder }              from '@store/selectors/orders';
import { selectCurrentOrderTimeSlot }      from '@store/selectors/orders';
import { selectCurrentOrderOrderProducts } from '@store/selectors/orders';
import { selectOrderSource }               from '@store/selectors/orders';

function* getCurrentOrderSessionId() {
    const currentOrderSessionId = yield SagaStateHelper.selectBySelector(selectCurrentOrderSessionId);

    if (currentOrderSessionId) {
        return currentOrderSessionId;
    }

    const newSessionId = uuidv4();

    yield put(OrdersActions.setCurrentOrderSessionId({
        sessionId: newSessionId,
    }));

    return newSessionId;
}

function* reserveTimeSlot(action) {
    yield put(LoadingActions.setReserving(true));

    const timeSlot  = _.get(action, 'payload.timeSlot', null);
    const sessionId = yield getCurrentOrderSessionId();
    const response  = yield call(Api.context.timeSlots.reserve(timeSlot.iri, sessionId));

    if (response.ok) {
        yield put(OrdersActions.reserveTimeSlotSucceeded({
            timeSlot,
        }));
    } else {
        yield put(OrdersActions.reserveTimeSlotFailed());
    }

    yield put(LoadingActions.setReserving(false));
}

function* reserveTimeSlotSucceeded(action) {
    const selectedTimeSlot = _.get(action, 'payload.timeSlot', null);
    const response         = yield call(Api.context.timeSlots.get(selectedTimeSlot.iri));

    if (!response.ok) {
        return;
    }

    const timeSlot = _.get(response, 'data', null);

    yield put(OrdersActions.setCurrentOrderTimeSlot({
        timeSlot,
    }));
    yield put(TimeSlotsActions.fetchTimeSlots());

    const currentServingType = yield SagaStateHelper.selectBySelector(selectCurrentOrderServingType);

    if (
        currentServingType === ServingType.delivery &&
        timeSlot.deliveryEnabled === false
    ) {
        yield put(OrdersActions.setCurrentOrderServingType({
            servingType: ServingType.clickAndCollect,
        }));
    }
}

function* checkIfAddressIsInDeliveryRadius(action) {
    const contact  = _.get(action, 'payload.contact', null);
    const address  = {
        street:      contact.street,
        postalCode:  contact.postalCode,
        houseNumber: contact.houseNumber,
        city:        contact.city,
    };
    const response = yield call(
        Api.context.address.distanceCheck,
        address,
    );

    if (!response.ok) {
        Notification.error('failedToCheckAddress');
        return;
    }

    const isInDeliveryRadius = _.get(response, 'data.inDeliveryRadius', false);

    yield put(OrdersActions.setAddressIsInDeliveryRadius({
        isInDeliveryRadius,
    }));
}

function* reserveTimeSlotFailed(action) {
    yield put(TimeSlotsActions.fetchTimeSlots());
    Notification.error('timeSlotAlreadyReserved');
}

function prepareOrderForPriceCalculation(order) {
    return {
        orderProducts: _.map(order.orderProducts, (orderProduct) => {
            return {
                position:         orderProduct.id,
                product:          orderProduct.product,
                quantity:         orderProduct.quantity,
                productAdditions: orderProduct.productAdditions,
                customerComment:  orderProduct.customerComment,
            };
        }),
    };
}

function prepareOrderForSubmit(order, orderSource) {
    return {
        firstname:          order.contact.firstName,
        lastname:           order.contact.lastName,
        email:              order.contact.email,
        customerComment:    order.contact.comment,
        phoneNumber:        order.contact.phoneNumber,
        address:            {
            street:      order.contact.street,
            postalCode:  order.contact.postalCode,
            houseNumber: order.contact.houseNumber,
            city:        order.contact.city,
        },
        additionalRequests: order.additionalRequests,
        timeSlot:           order.timeSlot.iri,
        servingType:        order.servingType,
        paymentType:        order.paymentType,
        source:             orderSource,
        ...prepareOrderForPriceCalculation(order),
    };
}

function prepareOrderResponseForFrontend(order) {
    return {
        ...order,
        orderProducts: _.map(order.orderProducts, (orderProduct) => {
            return {
                ...orderProduct,
                id: orderProduct.position,
            };
        }),
    };
}

function* addProductToCurrentOrder(action) {
    const product = _.get(action, 'payload.product', null);

    yield put(OrdersActions.addProductToCurrentOrderSucceeded({
        product,
    }));
}

function* submitOrder(action) {
    const currentOrder = yield SagaStateHelper.selectBySelector(selectCurrentOrder);
    const orderSource  = yield SagaStateHelper.selectBySelector(selectOrderSource);
    const order        = prepareOrderForSubmit(currentOrder, orderSource);
    const response     = yield call(Api.context.orders.submit, order);
    const successKey   = orderSource === OrderSource.online
        ? 'successfullySubmittedOrder'
        : 'successfullySubmittedOrderInStore';

    if (response.ok) {
        Notification.success(successKey);
        yield put(OrdersActions.submitOrderSucceeded({
            order: response.data,
        }));
    } else {
        yield put(OrdersActions.submitOrderFailed());

        const errorKey = _.get(response, 'data.error', 'failedToSubmitOrder');

        Notification.error(errorKey);
    }
}

function* submitOrderSucceeded(action) {
    const paymentType = _.get(action, 'payload.order.paymentType', null);

    if (paymentType === PaymentType.offline) {
        yield put(OverlayActions.closeOverlay());
        yield put(NavigationActions.redirect({
            route: Routes.home,
        }));
    }
}

function* confirmOrder() {
    const currentOrder = yield SagaStateHelper.selectBySelector(selectCurrentOrder);
    const orderId      = _.get(currentOrder, 'id', null);
    const response     = yield call(Api.context.orders.confirm, orderId);

    if (response.ok) {
        yield put(OrdersActions.confirmOrderSucceeded());
    } else {
        yield put(OrdersActions.confirmOrderFailed());
    }
}

function* confirmOrderFailed() {
    yield put(OverlayActions.closeOverlay());
    yield put(push(Routes.home));
    Notification.error('paymentTimeout');
}

function* clearReservedTimeSlot(action) {
    const sessionId = yield getCurrentOrderSessionId();
    const timeSlot  = yield SagaStateHelper.selectBySelector(selectCurrentOrderTimeSlot);
    const response  = yield call(Api.context.timeSlots.release(timeSlot.iri, sessionId));

    if (response.ok) {
        yield put(OrdersActions.clearReservedTimeSlotSucceeded());
        yield put(TimeSlotsActions.fetchTimeSlots());
        yield put(OverlayActions.closeOverlay({
            force: true,
        }));
        yield put(NavigationActions.redirect({
            route: Routes.home,
        }));

        const notificationKey = _.get(
            action,
            'payload.notificationKey',
            'timeSlotReservationExpired',
        );

        Notification.info(notificationKey);
    }
}

function* calculateOrderPrice() {
    const currentOrder = yield SagaStateHelper.selectBySelector(selectCurrentOrder);
    const order        = prepareOrderForPriceCalculation(currentOrder);
    const response     = yield call(Api.context.orders.calculatePrice, order);

    if (response.ok) {
        yield put(OrdersActions.calculateOrderPriceSucceeded({
            orderPriceData: prepareOrderResponseForFrontend(response.data),
        }));
    } else {
        Notification.error('failedToCalculateOrderPrice');
    }
}

function* showMoreThanEightPizzasPopup(action) {
    const type             = _.get(action, 'payload.product.type', null);
    const selectedTimeSlot = yield SagaStateHelper.selectBySelector(selectCurrentOrderTimeSlot);

    if (
        !selectedTimeSlot ||
        type !== ProductType.pizza ||
        selectedTimeSlot.availableSlots < OrderAppSettings.numberOfPizzasPerTimeSlot
    ) {
        return;
    }

    const currentOrderProducts = yield SagaStateHelper.selectBySelector(selectCurrentOrderOrderProducts);

    let numberOfPizzas = 0;

    _.forEach(currentOrderProducts, (orderProduct) => {
        if (orderProduct.type === ProductType.pizza) {
            numberOfPizzas += orderProduct.quantity;
        }
    });

    if (numberOfPizzas === OrderAppSettings.numberOfPizzasPerTimeSlot + 1) {
        yield put(OverlayActions.openOverlay({
            overlay: Overlay.tooBigOrder,
        }));
    }
}

function* submitPaymentSucceeded() {
    Notification.success('paymentSucceeded');
    yield put(OverlayActions.closeOverlay());
    yield put(NavigationActions.redirect({
        route: Routes.home,
    }));
}

function* submitPaymentFailed() {
    Notification.error('paymentFailed');
}

function* submitPaymentCanceled() {
    Notification.error('paymentCanceled');
}

function* checkOrderSource() {
    const storeUsage = Navigation.getQueryStringValue('storeUsage');
    let orderSource  = OrderSource.online;

    if (storeUsage === 'true') {
        orderSource = OrderSource.store;

        yield put(OrdersActions.setCurrentOrderPaymentType({
            paymentType: PaymentType.offline,
        }));
        yield put(OrdersActions.setCurrentOrderAgbAccepted({
            agbAccepted: true,
        }));
    }

    yield put(OrdersActions.setOrderSource({
        orderSource,
    }));
}

export default {
    reserveTimeSlot,
    reserveTimeSlotFailed,
    reserveTimeSlotSucceeded,
    submitOrder,
    submitOrderSucceeded,
    clearReservedTimeSlot,
    calculateOrderPrice,
    checkIfAddressIsInDeliveryRadius,
    submitPaymentSucceeded,
    submitPaymentFailed,
    submitPaymentCanceled,
    showMoreThanEightPizzasPopup,
    addProductToCurrentOrder,
    confirmOrder,
    confirmOrderFailed,
    checkOrderSource,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';

import { AdditionalRequestsActions }    from '@slices/additionalRequests';
import { AllergensActions }             from '@slices/allergens';
import { AppSettingsActions }           from '@slices/appSettings';
import { DeliveryExceptionsActions }    from '@slices/deliveryExceptions';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { OrdersActions }                from '@slices/orders';
import { OverlayActions }               from '@slices/overlay';
import { ProductAdditionsActions }      from '@slices/productAdditions';
import { ProductsActions }              from '@slices/products';
import { RestaurantsActions }           from '@slices/restaurants';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { TimeSlotsActions }             from '@slices/timeSlots';
import { WindowActions }                from '@slices/window';

import AdditionalRequestsSagas    from './additionalRequests';
import AllergensSagas             from './allergens';
import AppSettingsSagas           from './appSettings';
import DeliveryExceptionsSagas    from './deliveryExceptions';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import OrdersSagas                from './orders';
import OverlaySagas               from './overlay';
import PreLoadSagas               from './preload';
import ProductAdditionsSagas      from './productAdditions';
import ProductsSagas              from './products';
import RestaurantsSagas           from './restaurants';
import StagingAuthenticationSagas from './stagingAuthentication';
import TimeSlotsSagas             from './timeSlots';
import WindowSagas                from './window';

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callOverlaySagas = () => {
    return [
        // @formatter:off
        takeLatest([OverlayActions.closeOverlay().type], OverlaySagas.closeOverlay),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LoadingSagas.rehydrate),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        takeLatest([REHYDRATE], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

const callAppSettingsSagas = () => {
    return [
        // @formatter:off
        takeLatest([AppSettingsActions.fetchAppSettings().type],          AppSettingsSagas.fetchAppSettings),
        takeLatest([AppSettingsActions.fetchSettingsInInterval().type],   AppSettingsSagas.fetchSettingsInInterval),
        takeLatest([AppSettingsActions.fetchAppSettingsSucceeded().type], AppSettingsSagas.fetchAppSettingsSucceeded),
        // @formatter:on
    ];
};

const callProductSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductsActions.fetchProducts().type], ProductsSagas.fetchProducts),
        // @formatter:on
    ];
};

const callAllergensSagas = () => {
    return [
        // @formatter:off
        takeLatest([AllergensActions.fetchAllergens().type], AllergensSagas.fetchAllergens),
        // @formatter:on
    ];
};

const callAdditionalRequestsSagas = () => {
    return [
        // @formatter:off
        takeLatest([AdditionalRequestsActions.fetchAdditionalRequests().type], AdditionalRequestsSagas.fetchAdditionalRequests),
        // @formatter:on
    ];
};

const callProductAdditionsSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductAdditionsActions.fetchProductAdditions().type], ProductAdditionsSagas.fetchProductAdditions),
        takeLatest([ProductAdditionsActions.fetchProductAdditionsRecursively().type], ProductAdditionsSagas.fetchProductAdditionsRecursively),
        // @formatter:on
    ];
};

const callTimeSlotsSagas = () => {
    return [
        // @formatter:off
        takeLatest([
            TimeSlotsActions.fetchTimeSlots().type,
            TimeSlotsActions.resetTimeSlots().type,
        ], TimeSlotsSagas.fetchTimeSlots),
        // @formatter:on
    ];
};

const callDeliveryExceptionsSagas = () => {
    return [
        // @formatter:off
        takeLatest([DeliveryExceptionsActions.fetchDeliveryExceptions().type], DeliveryExceptionsSagas.fetchDeliveryExceptions),
        // @formatter:on
    ];
};

const callOrdersSagas = () => {
    return [
        // @formatter:off
        takeLatest([OrdersActions.reserveTimeSlot().type],                  OrdersSagas.reserveTimeSlot),
        takeLatest([OrdersActions.reserveTimeSlotFailed().type],            OrdersSagas.reserveTimeSlotFailed),
        takeLatest([OrdersActions.reserveTimeSlotSucceeded().type],         OrdersSagas.reserveTimeSlotSucceeded),
        takeLatest([OrdersActions.submitOrder().type],                      OrdersSagas.submitOrder),
        takeLatest([OrdersActions.submitOrderSucceeded().type],             OrdersSagas.submitOrderSucceeded),
        takeLatest([OrdersActions.clearReservedTimeSlot().type],            OrdersSagas.clearReservedTimeSlot),
        takeLatest([OrdersActions.calculateOrderPrice().type],              OrdersSagas.calculateOrderPrice),
        takeLatest([OrdersActions.checkIfAddressIsInDeliveryRadius().type], OrdersSagas.checkIfAddressIsInDeliveryRadius),
        takeLatest([OrdersActions.submitPaymentSucceeded().type],           OrdersSagas.submitPaymentSucceeded),
        takeLatest([OrdersActions.submitPaymentFailed().type],              OrdersSagas.submitPaymentFailed),
        takeLatest([OrdersActions.submitPaymentCanceled().type],            OrdersSagas.submitPaymentCanceled),
        takeLatest([OrdersActions.confirmOrder().type],                     OrdersSagas.confirmOrder),
        takeLatest([OrdersActions.confirmOrderFailed().type],               OrdersSagas.confirmOrderFailed),
        takeLatest([OrdersActions.addProductToCurrentOrder().type],         OrdersSagas.addProductToCurrentOrder),
        takeLatest([
            OrdersActions.incrementProductQuantityFromCurrentOrder().type,
            OrdersActions.addProductToCurrentOrderSucceeded().type,
        ],                                                                  OrdersSagas.showMoreThanEightPizzasPopup),
        takeLatest([OrdersActions.checkOrderSource().type],                 OrdersSagas.checkOrderSource),
        // @formatter:on
    ];
};

const callRestaurantsSaga = () => {
    return [
        // @formatter:off
        takeLatest([RestaurantsActions.fetchRestaurants().type],          RestaurantsSagas.fetchRestaurants),
        takeLatest([RestaurantsActions.fetchRestaurantsSucceeded().type], RestaurantsSagas.fetchRestaurantsSucceeded),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callStagingAuthenticationSagas(),
        ...callLoadingSagas(),
        ...callOverlaySagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callWindowSagas(),
        ...callRehydrateSagas(),
        ...callProductSagas(),
        ...callAppSettingsSagas(),
        ...callAllergensSagas(),
        ...callAdditionalRequestsSagas(),
        ...callProductAdditionsSagas(),
        ...callTimeSlotsSagas(),
        ...callOrdersSagas(),
        ...callDeliveryExceptionsSagas(),
        ...callRestaurantsSaga(),
    ]);
}

export default {
    root,
};

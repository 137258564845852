//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n           from 'i18next';
import _              from 'lodash';
import useForceUpdate from 'use-force-update';

import PropTypes    from '@components/PropTypes';
import NumberFormat from '@helper/NumberFormat';

import styles from './styles.module.scss';

const propTypes = {
    customRenderer: PropTypes.func,
    onCountDownEnd: PropTypes.func,
    showValueNames: PropTypes.bool,
    toTimeStamp:    PropTypes.number,
};

const CountDown = ({
    customRenderer = null,
    showValueNames = false,
    toTimeStamp    = null,
    onCountDownEnd = _.noop,
}) => {
    const forceUpdate                                 = useForceUpdate();
    let updateTimer                                   = null;
    const [countdownEndCalled, setCountdownEndCalled] = React.useState(false);

    useEffect(() => {
        updateTimer = window.setInterval(forceUpdate, 1000);

        return () => {
            if (updateTimer) {
                window.clearInterval(updateTimer);
            }
        };
    });

    function getTimeString() {
        const now               = new Date();
        const nowInSeconds      = now.getTime() / 1000;
        const totalSeconds      = toTimeStamp - nowInSeconds;
        const useCustomRenderer = (
            customRenderer &&
            _.isFunction(customRenderer)
        );

        if (totalSeconds < 0) {
            if (!countdownEndCalled) {
                onCountDownEnd();
                setCountdownEndCalled(true);
            }

            if (useCustomRenderer) {
                return customRenderer(
                    0,
                    0,
                    0,
                    0,
                );
            }

            return 0;
        }

        const leadingZeroCount = (
            showValueNames ?
                0 :
                2
        );
        const days             = Math.floor(totalSeconds / 86400);
        const hours            = NumberFormat.addLeadingZero(
            Math.floor((
                totalSeconds % 86400
            ) / 3600),
            leadingZeroCount,
        );
        const minutes          = NumberFormat.addLeadingZero(
            Math.floor((
                totalSeconds % (
                    3600
                )
            ) / 60),
            leadingZeroCount,
        );
        const seconds          = NumberFormat.addLeadingZero(
            Math.floor(totalSeconds % 60),
            leadingZeroCount,
        );

        if (useCustomRenderer) {
            return customRenderer(
                days,
                hours,
                minutes,
                seconds,
            );
        }

        const timeStringBuilder = [];

        if (days > 0) {
            timeStringBuilder.push(days);

            if (showValueNames) {
                const daysLanguageKey = (
                    days === 1
                        ? 'countDownDay'
                        : 'countDownDays'
                );

                timeStringBuilder.push(I18n.t(daysLanguageKey));
            }
        }

        timeStringBuilder.push(hours);

        if (showValueNames) {
            const hoursLanguageKey = (
                hours === 1
                    ? 'countDownHour'
                    : 'countDownHours'
            );

            timeStringBuilder.push(I18n.t(hoursLanguageKey));
        }

        timeStringBuilder.push(minutes);

        if (showValueNames) {
            const minutesLanguageKey = (
                minutes === 1
                    ? 'countDownMinute'
                    : 'countDownMinutes'
            );

            timeStringBuilder.push(I18n.t(minutesLanguageKey));
        }

        timeStringBuilder.push(seconds);

        if (showValueNames) {
            const secondsLanguageKey = (
                seconds === 1
                    ? 'countDownSecond'
                    : 'countDownSeconds'
            );

            timeStringBuilder.push(I18n.t(secondsLanguageKey));
        }

        const timeString = timeStringBuilder.join(showValueNames
            ? ' '
            : ':');

        return timeString;
    }

    const timeString = getTimeString();

    return (
        <span className={styles.wrapper}>
            {timeString}
        </span>
    );
};

CountDown.propTypes = propTypes;

export default CountDown;

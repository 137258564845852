//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                         from '@components/PropTypes';
import ServingType                       from '@constants/ServingType';
import Button                            from '@stateless/atomic/Button';
import ButtonColor                       from '@stateless/atomic/Button/ButtonColor';
import ButtonType                        from '@stateless/atomic/Button/ButtonType';
import IconType                          from '@stateless/atomic/Icon/IconType';
import { selectCurrentOrderServingType } from '@store/selectors/orders';
import { selectCurrentOrder }            from '@store/selectors/orders';

const propTypes = {
    disabled: PropTypes.bool,
    onClick:  PropTypes.func,
};

const CheckoutButton = ({
    onClick  = _.noop,
    disabled = false,
}) => {
    const servingType  = useSelector(selectCurrentOrderServingType);
    const currentOrder = useSelector(selectCurrentOrder);

    function getCheckoutButtonSubText() {
        if (servingType === ServingType.delivery) {
            return I18n.t('delivery');
        }

        if (servingType === ServingType.clickAndCollect) {
            return I18n.t('clickAndCollect');
        }

        return '';
    }

    return (
        <Button
            value={currentOrder.totalPrice}
            subText={getCheckoutButtonSubText()}
            color={ButtonColor.black}
            type={ButtonType.checkout}
            subIconLeft={IconType.checkout}
            onClick={onClick}
            disabled={disabled}
        />
    );
};

CheckoutButton.propTypes = propTypes;

export default CheckoutButton;

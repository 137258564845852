//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const english = Object.freeze({
    and:                     'and',
    more:                    'More',
    no:                      'No',
    oclock:                  'o\'clock',
    or:                      'or',
    pageTitle:               'Clausgemacht',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Yes',
});

export default english;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes       from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory }  from 'react-router';

import Routes                                   from '@constants/Routes';
import { OrdersActions }                        from '@slices/orders';
import StatelessCheckoutContent                 from '@stateless/composed/CheckoutContent';
import selectAdditionalRequests                 from '@store/selectors/additionalRequests';
import { selectActiveAppSetting }               from '@store/selectors/appSettings';
import { selectCurrentOrderServingType }        from '@store/selectors/orders';
import { selectCurrentOrderAdditionalRequests } from '@store/selectors/orders';
import { selectCurrentOrder }                   from '@store/selectors/orders';
import { selectCurrentOrderPaymentType }        from '@store/selectors/orders';
import { selectCurrentOrderClientSecret }       from '@store/selectors/orders';
import { selectOrderSource }                    from '@store/selectors/orders';
import { selectCurrentOrderAgbAccepted }        from '@store/selectors/orders';
import selectProductAdditions                   from '@store/selectors/productAdditions';
import { selectDrinks }                         from '@store/selectors/products';
import { selectProducts }                       from '@store/selectors/products';

const propTypes = {
    isModal:           PropTypes.bool,
    open:              PropTypes.bool,
    overlayContentRef: PropTypes.object,
};

const CheckoutContent = ({
    isModal           = false,
    open              = false,
    overlayContentRef = null,
}) => {
    const dispatch                   = useDispatch();
    const additionalRequests         = useSelector(selectAdditionalRequests);
    const productAdditions           = useSelector(selectProductAdditions);
    const currentOrderServingType    = useSelector(selectCurrentOrderServingType);
    const checkoutProducts           = useSelector(selectDrinks);
    const allProducts                = useSelector(selectProducts);
    const allProductAdditions        = useSelector(selectProductAdditions);
    const selectedAdditionalRequests = useSelector(selectCurrentOrderAdditionalRequests);
    const currentOrder               = useSelector(selectCurrentOrder);
    const appSetting                 = useSelector(selectActiveAppSetting);
    const location                   = useLocation();
    const history                    = useHistory();
    const selectedPaymentType        = useSelector(selectCurrentOrderPaymentType);
    const paymentClientSecret        = useSelector(selectCurrentOrderClientSecret);
    const orderSource                = useSelector(selectOrderSource);
    const agbAccepted                = useSelector(selectCurrentOrderAgbAccepted);

    function createAddProductClicked(product) {
        return () => {
            dispatch(OrdersActions.addProductToCurrentOrder({
                product,
            }));
        };
    }

    function createDecrementProductClicked(orderProduct) {
        return () => {
            dispatch(OrdersActions.decrementProductQuantityFromCurrentOrder({
                orderProduct,
            }));
        };
    }

    function createIncrementProductClicked(orderProduct) {
        return () => {
            dispatch(OrdersActions.incrementProductQuantityFromCurrentOrder({
                orderProduct,
            }));
        };
    }

    function createOnProductAdditionClicked(productAddition, orderProduct) {
        return () => {
            dispatch(OrdersActions.toggleProductAdditionFromOrderProduct({
                orderProduct,
                productAddition,
            }));
        };
    }

    function createOnCustomerCommentChanged(orderPosition) {
        return (comment) => {
            dispatch(OrdersActions.setCurrentOrderCustomerComment({
                orderPosition,
                comment,
            }));
        };
    }

    function createOnAdditionalRequestClicked(additionalRequest) {
        return () => {
            dispatch(OrdersActions.toggleAdditionalRequestForCurrentOrder({
                additionalRequest,
            }));
        };
    }

    function onSubmitOrderClicked() {
        // The timeouts purpose is to see the TrackButtons animation
        setTimeout(
            () => {
                dispatch(OrdersActions.submitOrder());
            },
            200,
        );
    }

    function onSubmitPaymentCanceled() {
        dispatch(OrdersActions.submitPaymentCanceled());
        history.push(Routes.home);
    }

    function setAgbAccepted(accepted) {
        dispatch(OrdersActions.setCurrentOrderAgbAccepted({
            agbAccepted: accepted,
        }));
    }

    if (
        location.pathname === Routes.checkout &&
        isModal
    ) {
        return null;
    }

    return (
        <StatelessCheckoutContent
            additionalRequests={additionalRequests}
            agbAccepted={agbAccepted}
            allProductAdditions={allProductAdditions}
            allProducts={allProducts}
            appSetting={appSetting}
            createAddProductClicked={createAddProductClicked}
            createDecrementProductClicked={createDecrementProductClicked}
            createIncrementProductClicked={createIncrementProductClicked}
            createOnAdditionalRequestClicked={createOnAdditionalRequestClicked}
            createOnCustomerCommentChanged={createOnCustomerCommentChanged}
            createOnProductAdditionClicked={createOnProductAdditionClicked}
            currentOrder={currentOrder}
            isModal={isModal}
            onSubmitOrderClicked={onSubmitOrderClicked}
            open={open}
            orderSource={orderSource}
            overlayContentRef={overlayContentRef}
            paymentClientSecret={paymentClientSecret}
            productAdditions={productAdditions}
            recommendedProducts={checkoutProducts}
            selectedAdditionalRequests={selectedAdditionalRequests}
            selectedPaymentType={selectedPaymentType}
            selectedServingType={currentOrderServingType}
            setAgbAccepted={setAgbAccepted}
            submitPaymentCanceled={onSubmitPaymentCanceled}
        />
    );
};

CheckoutContent.propTypes = propTypes;

export default CheckoutContent;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                           from '@components/PropTypes';
import DateTime                            from '@helper/DateTime';
import { OrdersActions }                   from '@slices/orders';
import StatelessTimeSlotItem               from '@stateless/atomic/TimeSlotItem';
import { selectActiveAppSetting }          from '@store/selectors/appSettings';
import { selectCurrentOrderTimeSlot }      from '@store/selectors/orders';
import { selectCurrentOrderPizzaQuantity } from '@store/selectors/orders';
import { selectCurrentOrderSessionId }     from '@store/selectors/orders';
import { selectSingleTimeSlot }            from '@store/selectors/timeSlots';

const propTypes = {
    timeSlotIri: PropTypes.string,
};

const TimeSlotItem = ({
    timeSlotIri = '',
}) => {
    const dispatch              = useDispatch();
    const timeSlot              = useSelector(selectSingleTimeSlot(timeSlotIri));
    const selectedTimeSlot      = useSelector(selectCurrentOrderTimeSlot);
    const selectedPizzaQuantity = useSelector(selectCurrentOrderPizzaQuantity);
    const currentOrderSessionId = useSelector(selectCurrentOrderSessionId);
    const selected              = selectedTimeSlot?.iri === timeSlot?.iri;
    const text                  = DateTime.getHoursAndMinutes(timeSlot?.startDateTime);
    const tooSmall              = selectedPizzaQuantity > timeSlot?.availableSlots;
    const activeAppSetting      = useSelector(selectActiveAppSetting);
    const soldOutTill           = activeAppSetting?.soldOutTill;
    const isInSoldOutPeriod     = DateTime.isOnOrBeforeDay(soldOutTill, timeSlot?.startDateTime);
    const disabled              = (
        isInSoldOutPeriod ||
        (
            timeSlot?.reservedTill !== null &&
            timeSlot?.reservedSessionKey !== currentOrderSessionId
        ) ||
        timeSlot?.availableSlots === 0
    );

    function onTimeSlotClicked() {
        if (timeSlot.iri === selectedTimeSlot?.iri) {
            return;
        }

        dispatch(OrdersActions.reserveTimeSlot({
            timeSlot,
        }));
    }

    if (!timeSlot) {
        return null;
    }

    return (
        <StatelessTimeSlotItem
            tooSmall={tooSmall}
            text={text}
            selected={selected}
            onClick={onTimeSlotClicked}
            disabled={disabled}
        />
    );
};

TimeSlotItem.propTypes = propTypes;

export default TimeSlotItem;

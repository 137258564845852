//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes  from '@components/DefaultTypes';
import PropTypes     from '@components/PropTypes';
import CheckBoxColor from '@stateless/atomic/CheckBox/CheckBoxColor';
import Icon          from '@stateless/atomic/Icon';
import IconType      from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    checked:        PropTypes.bool,
    color:          PropTypes.oneOfObjectValues(CheckBoxColor),
    noMarginBottom: PropTypes.bool,
    onClick:        PropTypes.func,
    text:           PropTypes.element,
};

const CheckBox = ({
    checked        = false,
    noMarginBottom = false,
    onClick        = DefaultTypes.func,
    text           = null,
    color          = null,
}) => {
    function renderIcon() {
        if (checked) {
            return (
                <Icon iconType={IconType.checked} />
            );
        }

        return (
            <Icon iconType={IconType.unchecked} />
        );
    }

    function renderCheckBox() {
        return (
            <div
                className={classNames(
                    styles.checkBox,
                    {
                        [styles.green]: color === CheckBoxColor.green,
                    },
                )}
            >
                {renderIcon()}
            </div>
        );
    }

    const handleClick = (event) => {
        const tagName  = event.target.tagName;
        const isAnchor = tagName === 'A';

        if (!isAnchor) {
            onClick();
        }
    };

    return (
        <div
            className={
                classNames(
                    styles.checkBoxWrapper,
                    {
                        [styles.checkBoxWrapperNoMarginBottom]: noMarginBottom,
                    },
                )
            }
        >
            <div
                className={styles.checkBoxWrapperClickArea}
                onClick={handleClick}
            >
                {renderCheckBox()}
                <div className={styles.checkBoxWrapperLabel}>
                    {text}
                </div>
            </div>
        </div>
    );
};

CheckBox.propTypes = propTypes;

export default CheckBox;

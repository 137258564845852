//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes         from '@components/PropTypes';
import OpeningHours      from '@stateless/atomic/OpeningHours';
import Overlay           from '@stateless/atomic/Overlay';
import OverlaySize       from '@stateless/atomic/Overlay/OverlaySize';
import RestaurantAddress from '@stateless/atomic/RestaurantAddress';

import styles from './styles.module.scss';

const propTypes = {
    onClose:    PropTypes.func,
    open:       PropTypes.bool,
    restaurant: PropTypes.object,
};

const MobileRestaurantInfoOverlay = ({
    onClose    = _.noop,
    open       = false,
    restaurant = {},
}) => {
    const openingHours = _.get(restaurant, 'openingHours', []);

    return (
        <Overlay
            onCloseClick={onClose}
            open={open}
            size={OverlaySize.fullScreen}
        >
            <div
                className={styles.mobileRestaurantInfoOverlay}
            >
                <OpeningHours
                    openingHours={openingHours}
                    isMobile={true}
                />
                <RestaurantAddress
                    restaurant={restaurant}
                />
            </div>
        </Overlay>
    );
};

MobileRestaurantInfoOverlay.propTypes = propTypes;

export default MobileRestaurantInfoOverlay;

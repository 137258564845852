//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export const informationData = {
    titleKey:  'information',
    linkItems: [
        {
            translationKey: 'privacyPolicy',
            hrefKey:        'privacyPolicyUrl',
        },
        {
            translationKey: 'imprint',
            hrefKey:        'imprintUrl',
        },
        {
            translationKey: 'terms',
            hrefKey:        'termsUrl',
        },
    ],
};

export const contactData = {
    titleKey:  'contact',
    linkItems: [
        {
            translationKey: 'email',
            hrefKey:        'emailUrl',
        },
        {
            translationKey: 'phone',
            hrefKey:        'phoneUrl',
        },
    ],
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes       from '@components/PropTypes';
import CheckoutContent from '@connected/CheckoutContent';
import Overlay         from '@stateless/atomic/Overlay';
import OverlaySize     from '@stateless/atomic/Overlay/OverlaySize';

const propTypes = {
    onClose: PropTypes.func,
    open:    PropTypes.bool,
};

const CheckoutOverlay = ({
    onClose = _.noop,
    open    = false,
}) => {
    return (
        <Overlay
            onCloseClick={onClose}
            open={open}
            size={OverlaySize.wide}
        >
            <CheckoutContent
                open={open}
                isModal={true}
                overlayContentRef={null}
            />
        </Overlay>
    );
};

CheckoutOverlay.propTypes = propTypes;

export default CheckoutOverlay;

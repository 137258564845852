//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes        from '@components/PropTypes';
import OrderAppSettings from '@constants/OrderAppSettings';

import styles from './styles.module.scss';

const propTypes = {
    onPizzaQuantityClicked: PropTypes.func,
    selectedPizzaQuantity:  PropTypes.number,
};

const PizzaQuantitySelector = ({
    selectedPizzaQuantity  = 0,
    onPizzaQuantityClicked = _.noop,
}) => {
    const [pressed, setPressed] = React.useState(null);
    const onQuantityClicked     = (index) => () => {
        onPizzaQuantityClicked(index);
    };
    const onMouseDown           = (index) => () => {
        setPressed(index);
    };
    const onMouseUp             = () => {
        setPressed(null);
    };

    function renderPizzaQuantityButtons() {
        return _.times(OrderAppSettings.numberOfPizzasPerTimeSlot, (index) => {
            const numberOfPizzas = index + 1;

            return (
                <div
                    key={index}
                    className={
                        classNames(
                            styles.pizzaQuantityButton,
                            {
                                [styles.selected]: numberOfPizzas === selectedPizzaQuantity,
                                [styles.pressed]:  numberOfPizzas === pressed,
                            },
                        )
                    }
                    onMouseDown={onMouseDown(numberOfPizzas)}
                    onMouseUp={onMouseUp}
                    onClick={onQuantityClicked(numberOfPizzas)}
                >
                    {numberOfPizzas}
                </div>
            );
        });
    }

    return (
        <div className={styles.pizzaQuantitySelector}>
            {renderPizzaQuantityButtons()}
        </div>
    );
};

PizzaQuantitySelector.propTypes = propTypes;

export default PizzaQuantitySelector;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ProductType from '@constants/ProductType';

class ProductHelper {
    static getPizza(products) {
        return _.filter(products, {
            type: ProductType.pizza,
        });
    }

    static getDrinks(products) {
        return _.filter(products, {
            type: ProductType.drink,
        });
    }

    static getVegetarianVeganPizza(products) {
        return _.filter(products, (product) => {
            return (
                product.type === ProductType.pizza &&
                (
                    product.vegan ||
                    product.vegetarian
                )
            );
        });
    }

    static getVeganPizza(products) {
        return _.filter(products, {
            type:  ProductType.pizza,
            vegan: true,
        });
    }

    static filterProducts(products, type, onlyVegan, onlyVegetarian) {
        if (type === ProductType.drink) {
            return this.getDrinks(products);
        }

        if (type === ProductType.pizza) {
            if (onlyVegan) {
                return this.getVeganPizza(products);
            }

            if (onlyVegetarian) {
                return this.getVegetarianVeganPizza(products);
            }

            return this.getPizza(products);
        }

        return [];
    }
}

export default ProductHelper;

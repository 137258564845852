//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useTranslation } from 'react-i18next';

import PropTypes        from '@components/PropTypes';
import ServingType      from '@constants/ServingType';
import LabeledTextInput from '@stateless/atomic/LabeledTextInput';

import styles from './styles.module.scss';

const propTypes = {
    fields:             PropTypes.array,
    isCheckout:         PropTypes.bool,
    isInDeliveryRadius: PropTypes.bool,
    servingType:        PropTypes.oneOfObjectValues(ServingType),
};

const AddressForm = ({
    fields             = [],
    servingType        = '',
    isInDeliveryRadius = null,
    isCheckout         = false,
}) => {
    const { t: translator } = useTranslation(null, {
        keyPrefix: 'components.addressForm',
    });

    function renderFields() {
        return fields.map((field) => {
            if (
                field.onlyDelivery &&
                servingType !== ServingType.delivery
            ) {
                return null;
            }

            if (
                !isCheckout &&
                field.onlyCheckout
            ) {
                return null;
            }

            return (
                <LabeledTextInput
                    key={field.name}
                    labelText={field.label}
                    onChange={field.onChange}
                    onKeyDown={field.onChange}
                    value={field.value}
                    type={field.type}
                    required={field.required}
                />
            );
        });
    }

    function renderIsInDeliveryRadiusLabel() {
        if (
            isInDeliveryRadius === null ||
            servingType !== ServingType.delivery
        ) {
            return null;
        }

        return (
            <div className={styles.isInDeliveryRadiusLabel}>
                {translator(isInDeliveryRadius
                    ? 'isInDeliveryRadius'
                    : 'isNotInDeliveryRadius')}
            </div>
        );
    }

    return (
        <div className={styles.addressForm}>
            {renderFields()}
            {renderIsInDeliveryRadiusLabel()}
        </div>
    );
};

AddressForm.propTypes = propTypes;

export default AddressForm;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';
import { useState }  from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { TimeSlotsActions }  from '@slices/timeSlots';
import selectWindowIsVisible from '@store/selectors/window';

const propTypes = {};

const TIMESLOT_FETCH_INTERVAL_IN_SECONDS = 1000 * 10;

const TimeSlotFetcher = () => {
    const dispatch                    = useDispatch();
    const [intervalId, setIntervalId] = useState(null);
    const windowIsVisible             = useSelector(selectWindowIsVisible);

    useEffect(() => {
        setIntervalId(setInterval(() => {
            if (!windowIsVisible) {
                return;
            }

            dispatch(TimeSlotsActions.fetchTimeSlots());
        }, TIMESLOT_FETCH_INTERVAL_IN_SECONDS));

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return null;
};

TimeSlotFetcher.propTypes = propTypes;

export default TimeSlotFetcher;

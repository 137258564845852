//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                         from '@components/PropTypes';
import PaymentType                       from '@constants/PaymentType';
import ServingType                       from '@constants/ServingType';
import { OrdersActions }                 from '@slices/orders';
import Button                            from '@stateless/atomic/Button';
import ButtonColor                       from '@stateless/atomic/Button/ButtonColor';
import ButtonType                        from '@stateless/atomic/Button/ButtonType';
import IconType                          from '@stateless/atomic/Icon/IconType';
import { selectCurrentOrderPaymentType } from '@store/selectors/orders';
import { selectCurrentOrderServingType } from '@store/selectors/orders';

const propTypes = {
    paymentType: PropTypes.oneOfObjectValues(PaymentType),
};

const PaymentTypeButton = ({
    paymentType = null,
}) => {
    const dispatch                = useDispatch();
    const currentOrderPaymentType = useSelector(selectCurrentOrderPaymentType);
    const selectedServiceType     = useSelector(selectCurrentOrderServingType);
    const buttonColor             = currentOrderPaymentType === paymentType
        ? ButtonColor.black
        : ButtonColor.gray;

    function getIconType() {
        switch (paymentType) {
            case PaymentType.online:
                return IconType.checkout;
            case PaymentType.offline:
                return IconType.delivery;
            default:
                return null;
        }
    }

    function getButtonText() {
        switch (paymentType) {
            case PaymentType.online:
                return I18n.t('paymentOnline');
            case PaymentType.offline:
                if (selectedServiceType === ServingType.delivery) {
                    return I18n.t('paymentOfflineDelivery');
                }

                return I18n.t('paymentOfflineCollection');
            default:
                return null;
        }
    }

    function handleButtonClicked() {
        dispatch(OrdersActions.setCurrentOrderPaymentType({
            paymentType,
        }));
    }

    return (
        <Button
            type={ButtonType.pill}
            text={getButtonText()}
            color={buttonColor}
            disabled={false}
            iconLeft={getIconType()}
            onClick={handleButtonClicked}
        />
    );
};

PaymentTypeButton.propTypes = propTypes;

export default PaymentTypeButton;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes            from '@components/PropTypes';
import IconType             from '@stateless/atomic/Icon/IconType';
import StatelessInfoOverlay from '@stateless/composed/InfoOverlay';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const TooBigOrderOverlay = ({
    onCloseClick = _.noop,
    open         = false,
}) => {
    const infoBoxItems = [
        {
            titleKey: 'bigOrdersTitle',
            textKey:  'bigOrdersText',
            iconType: IconType.forms,
        },
    ];

    return (
        <StatelessInfoOverlay
            onClose={onCloseClick}
            open={open}
            infoItems={infoBoxItems}
        />
    );
};

TooBigOrderOverlay.propTypes = propTypes;

export default TooBigOrderOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

import PropTypes    from '@components/PropTypes';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Overlay      from '@stateless/atomic/Overlay';
import OverlaySize  from '@stateless/atomic/Overlay/OverlaySize';

import styles from './styles.module.scss';

const propTypes = {
    allergens: PropTypes.array,
    onClose:   PropTypes.func,
    open:      PropTypes.bool,
    product:   PropTypes.object,
};

const ProductInfoOverlay = ({
    onClose   = _.noop,
    open      = false,
    product   = null,
    allergens = [],
}) => {
    const { t: translator } = useTranslation(null, {
        keyPrefix: 'components.productInfoOverlay',
    });

    function renderListItems() {
        if (
            !product ||
            !product.allergens
        ) {
            return null;
        }

        return product.allergens.map((allergenIri) => {
            const allergen = _.find(allergens, {
                iri: allergenIri,
            });

            if (!allergen) {
                return null;
            }

            return (
                <li key={allergen}>
                    {allergen.description}
                </li>
            );
        });
    }

    return (
        <Overlay
            onCloseClick={onClose}
            open={open}
            size={OverlaySize.small}
        >
            <div className={styles.productInfoOverlay}>
                <Headline
                    title={translator('additionalProductInformation')}
                    type={HeadlineType.headline2}
                />
                <Headline
                    title={translator('allergens')}
                    type={HeadlineType.headline3}
                />
                <ul>
                    {renderListItems()}
                </ul>
            </div>
        </Overlay>
    );
};

ProductInfoOverlay.propTypes = propTypes;

export default ProductInfoOverlay;

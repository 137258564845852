//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                   from '@components/PropTypes';
import StatelessProductInfoOverlay from '@stateless/composed/ProductInfoOverlay';
import selectAllergens             from '@store/selectors/allergens';
import { selectProductInDetail }   from '@store/selectors/products';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const ProductInfoOverlay = ({
    onCloseClick = _.noop,
    open         = false,
}) => {
    const product   = useSelector(selectProductInDetail);
    const allergens = useSelector(selectAllergens);

    return (
        <StatelessProductInfoOverlay
            onClose={onCloseClick}
            open={open}
            product={product}
            allergens={allergens}
        />
    );
};

ProductInfoOverlay.propTypes = propTypes;

export default ProductInfoOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { OrdersActions }                   from '@slices/orders';
import StatelessPizzaQuantitySelector      from '@stateless/composed/PizzaQuantitySelector';
import { selectCurrentOrderPizzaQuantity } from '@store/selectors/orders';

const propTypes = {};

const PizzaQuantitySelector = () => {
    const dispatch              = useDispatch();
    const selectedPizzaQuantity = useSelector(selectCurrentOrderPizzaQuantity);

    function handlePizzaQuantityClicked(pizzaQuantity) {
        dispatch(OrdersActions.setCurrentOrderPizzaQuantity({
            pizzaQuantity,
        }));
    }

    return (
        <StatelessPizzaQuantitySelector
            selectedPizzaQuantity={selectedPizzaQuantity}
            onPizzaQuantityClicked={handlePizzaQuantityClicked}
        />
    );
};

PizzaQuantitySelector.propTypes = propTypes;

export default PizzaQuantitySelector;

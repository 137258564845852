//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = {
    hoverText: PropTypes.string,
    iconType:  PropTypes.iconType,
};

const HoverTextIcon = ({
    iconType  = null,
    hoverText = '',
}) => {
    return (
        <div className={styles.hoverTextIcon}>
            <Icon
                iconType={iconType}
            />
            <div className={styles.hoverTextContainer}>
                {hoverText}
            </div>
        </div>
    );
};

HoverTextIcon.propTypes = propTypes;

export default HoverTextIcon;

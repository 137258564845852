//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as EmailValidator from 'email-validator';
import _                   from 'lodash';

import OrderSource  from '@constants/OrderSource';
import ProductType  from '@constants/ProductType';
import ServingType  from '@constants/ServingType';
import Notification from '@helper/Notification';

class OrderValidator {
    static isOrderValid(order, minOrderValue, orderSource) {
        if (!OrderValidator.isOrderComplete(order, orderSource)) {
            Notification.error('orderNotComplete');

            return false;
        }

        if (
            !EmailValidator.validate(order.contact.email) &&
            (
                orderSource !== OrderSource.store &&
                order.contact.email
            )
        ) {
            Notification.error('emailNotValid');

            return false;
        }

        const numberOfProducts = _.sumBy(order.orderProducts, (orderProduct) => {
            if (orderProduct.type === ProductType.pizza) {
                return orderProduct.quantity;
            }

            return 0;
        });
        const availableSlots   = order.timeSlot.availableSlots;

        if (availableSlots < numberOfProducts) {
            Notification.error(
                'tooManyProductsForTimeSlot',
                {
                    numberOfProducts,
                    availableSlots,
                },
            );

            return false;
        }

        if (
            order.servingType === ServingType.delivery &&
            order.totalPrice < minOrderValue
        ) {
            Notification.error(
                'minOrderValueForDeliveryNotReached',
                {
                    minOrderValue,
                },
            );

            return false;
        }

        return true;
    }

    static isOrderComplete(order, orderSource) {
        const completeConditions            = [
            order,
            order.orderProducts.length > 0,
            order.timeSlot,
            (
                order.servingType === ServingType.clickAndCollect ||
                (
                    order.servingType === ServingType.delivery &&
                    order.isInDeliveryRadius
                )
            ),
            order.paymentType,
        ];
        const onlineDependingConditions     = [
            order.contact,
            order.contact.firstName,
            order.contact.lastName,
            order.contact.email,
            order.contact.phoneNumber,
            order.agbAccepted,
        ];
        const completeConditionsForDelivery = [
            order.contact.street,
            order.contact.houseNumber,
            order.contact.postalCode,
            order.contact.city,
        ];

        if (orderSource === OrderSource.online) {
            completeConditions.push(...onlineDependingConditions);
        }

        if (order.servingType === ServingType.delivery) {
            completeConditions.push(...completeConditionsForDelivery);
        }

        let isComplete = true;

        _.forEach(completeConditions, (condition) => {
            if (!condition) {
                isComplete = false;
            }
        });

        return isComplete;
    }
}

export default OrderValidator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import OrderSource from '@constants/OrderSource';

const basePath = [
    'orders',
];

const currentOrderPath = [
    ...basePath,
    'currentOrder',
];

export const selectOrderSource = (state) => {
    return _.get(state, [...basePath, 'orderSource'], OrderSource.online);
};

export const selectCurrentOrder = (state) => {
    return _.get(state, currentOrderPath, null);
};

export const selectCurrentOrderTimeSlot = (state) => {
    return _.get(state, [...currentOrderPath, 'timeSlot'], null);
};

export const selectCurrentOrderSessionId = (state) => {
    return _.get(state, [...currentOrderPath, 'sessionId'], null);
};

export const selectCurrentOrderServingType = (state) => {
    return _.get(state, [...currentOrderPath, 'servingType'], null);
};

export const selectCurrentOrderPaymentType = (state) => {
    return _.get(state, [...currentOrderPath, 'paymentType'], null);
};

export const selectCurrentOrderClientSecret = (state) => {
    return _.get(state, [...currentOrderPath, 'clientSecret'], null);
};

export const selectCurrentOrderAgbAccepted = (state) => {
    return _.get(state, [...currentOrderPath, 'agbAccepted'], false);
};

export const selectCurrentOrderConfirmed = (state) => {
    return _.get(state, [...currentOrderPath, 'confirmed'], null);
};

export const selectCurrentOrderContact = (state) => {
    return _.get(state, [...currentOrderPath, 'contact'], {});
};

export const selectCurrentOrderOrderProducts = (state) => {
    return _.get(state, [...currentOrderPath, 'orderProducts'], []);
};

export const selectCurrentOrderPizzaQuantity = (state) => {
    return _.get(state, [...currentOrderPath, 'pizzaQuantity'], 0);
};

export const selectCurrentOrderAdditionalRequests = (state) => {
    return _.get(state, [...currentOrderPath, 'additionalRequests'], []);
};

export const selectCurrentOrderIsInDeliveryRadius = (state) => {
    return _.get(state, [...currentOrderPath, 'isInDeliveryRadius'], null);
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

const initialState = Object.freeze({
    clickCount:          0,
    isLoading:           false,
    isReserving:         false,
    loadingLevelActions: [],
});

const getDefaultLoadingText = () => {
    console.warn('⚠️⚠️⚠️ Warning: Default loading text is used ⚠️⚠️⚠️');

    return I18n.t('loading');
};

const loadingSlice = createSlice({
    name:     'loading',
    initialState,
    reducers: {
        decreaseLevel:   (state, action) => {
            const loadingAction             = _.get(action, 'payload.loadingAction', []);
            const loadingLevelActions       = _.get(state, 'loadingLevelActions', []);
            const actionTypeFetch           = (
                loadingAction.type
                    .replace('Failed', '')
                    .replace('Succeeded', '')
            );
            const updateLoadingLevelActions = _.without(loadingLevelActions, actionTypeFetch);

            return update(state, {
                clickCount:          {
                    $set: initialState.clickCount,
                },
                isLoading:           {
                    $set: !_.isEmpty(updateLoadingLevelActions),
                },
                loadingLevelActions: {
                    $set: updateLoadingLevelActions,
                },
            });
        },
        increaseLevel:   (state, action) => {
            const loadingAction        = _.get(action, 'payload.loadingAction', {});
            const loadingActionPayload = _.get(loadingAction, 'payload', {});
            const loadingLevelActions  = _.get(state, 'loadingLevelActions', []);
            const loadingLevelPush     = (
                !_.includes(loadingLevelActions, loadingAction.type)
                    ?
                    {
                        loadingLevelActions: {
                            $push: [
                                loadingAction.type,
                            ],
                        },
                    } :
                    null
            );

            return update(state, {
                clickCount: {
                    $set: initialState.clickCount,
                },
                isLoading:  {
                    $set: true,
                },
                text:       {
                    $set:
                        (
                            loadingActionPayload.text ||
                            getDefaultLoadingText()
                        ),
                },
                ...loadingLevelPush,
            });
        },
        rehydrate:       (state, action) => {
            return update(state, {
                isLoading:           {
                    $set: false,
                },
                loadingLevelActions: {
                    $set: [],
                },
            });
        },
        resetOverlay:    (state, action) => {
            return update(state, {
                clickCount: {
                    $set: initialState.clickCount,
                },
                isLoading:  {
                    $set: initialState.isLoading,
                },
                level:      {
                    $set: initialState.level,
                },
            });
        },
        resetClickCount: (state, action) => {
            return update(state, {
                clickCount: {
                    $set: initialState.clickCount,
                },
            });
        },
        overlayClicked:  (state, action) => {
            return update(state, {
                clickCount: {
                    $set: state.clickCount + 1,
                },
            });
        },
        setReserving:    (state, action) => {
            return update(state, {
                isReserving: {
                    $set: action.payload,
                },
            });
        },
    },
});

export const LoadingActions = loadingSlice.actions;

export const LoadingReducer = loadingSlice.reducer;

export const useLoading = (dispatch) => bindActionCreators(LoadingActions, dispatch);

export default loadingSlice;

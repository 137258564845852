//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';
import { useState }  from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { OrdersActions }              from '@slices/orders';
import { selectCurrentOrderTimeSlot } from '@store/selectors/orders';

const propTypes = {};

const CHECK_INTERVAL_IN_SECONDS = 1000 * 1;

const TimeSlotExpirationWatcher = () => {
    const dispatch                    = useDispatch();
    const [intervalId, setIntervalId] = useState(0);
    const selectedTimeSlot            = useSelector(selectCurrentOrderTimeSlot);

    useEffect(
        () => {
            if (selectedTimeSlot?.reservedTill) {
                const expireInterval = setInterval((id) => {
                    const now            = new Date();
                    const expirationDate = new Date(selectedTimeSlot.reservedTill);

                    if (now > expirationDate) {
                        dispatch(OrdersActions.clearReservedTimeSlot());
                        clearInterval(expireInterval);
                    }
                }, CHECK_INTERVAL_IN_SECONDS);

                setIntervalId(expireInterval);
            }
            return () => {
                clearInterval(intervalId);
            };
        },
        [selectedTimeSlot],
    );

    return null;
};

TimeSlotExpirationWatcher.propTypes = propTypes;

export default TimeSlotExpirationWatcher;

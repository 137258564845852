//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import moment from 'moment';

const basePath = [
    'timeSlots',
];

export const selectTimeSlots = (state) => {
    return _.get(state, [...basePath, 'timeSlots'], []);
};

export const selectSingleTimeSlot = (iri) => (state) => {
    return _.find(selectTimeSlots(state), {
        iri,
    });
};

export const selectNextDeliveryEnabledTimeslot = (noDeliveryTimeSlot) => (state) => {
    if (!noDeliveryTimeSlot) {
        return null;
    }

    const timeSlots  = selectTimeSlots(state);
    const dateMoment = moment(noDeliveryTimeSlot.startDateTime);

    return timeSlots.find((timeSlot) => {
        const timeSlotMoment = moment(timeSlot.startDateTime);

        return (
            timeSlotMoment.isSameOrAfter(dateMoment) &&
            timeSlot.deliveryEnabled &&
            !timeSlot.deliveryBlocked
        );
    });
};

export const selectGroupedTimeSlots = (state) => {
    return _.get(state, [...basePath, 'groupedTimeSlots'], {});
};

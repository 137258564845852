//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes    from '@components/PropTypes';
import CheckBox     from '@stateless/atomic/CheckBox';
import Counter      from '@stateless/atomic/Counter';
import Headline     from '@stateless/atomic/Headline';
import HeadlineType from '@stateless/atomic/Headline/HeadlineType';
import Price        from '@stateless/atomic/Price';
import PriceColor   from '@stateless/atomic/Price/PriceColor';
import NoteInput    from '@stateless/composed/NoteInput';

import styles from './styles.module.scss';

const propTypes = {
    createOnProductAdditionClicked: PropTypes.func,
    displayOnly:                    PropTypes.bool,
    onCustomerCommentChanged:       PropTypes.func,
    onDecrementClicked:             PropTypes.func,
    onIncrementClicked:             PropTypes.func,
    orderProduct:                   PropTypes.object,
    positionSum:                    PropTypes.number,
    product:                        PropTypes.object,
    productAdditions:               PropTypes.array,
    quantity:                       PropTypes.number,
    selectedProductAdditions:       PropTypes.array,
};

const OrderPosition = ({
    product                        = {},
    displayOnly                    = false,
    productAdditions               = [],
    onIncrementClicked             = _.noop,
    onDecrementClicked             = _.noop,
    selectedProductAdditions       = [],
    positionSum                    = 0,
    quantity                       = 0,
    createOnProductAdditionClicked = _.noop,
    orderProduct                   = null,
    onCustomerCommentChanged       = _.noop,
}) => {
    function renderAdditions() {
        return (
            _.map(productAdditions, (productAddition) => {
                if (productAddition.targetType !== product.type) {
                    return null;
                }

                const isChecked = _.includes(
                    selectedProductAdditions,
                    productAddition.iri,
                );

                if (
                    displayOnly &&
                    !isChecked
                ) {
                    return null;
                }

                const onProductAdditionClicked = createOnProductAdditionClicked(
                    productAddition,
                    orderProduct,
                );

                return (
                    <CheckBox
                        checked={isChecked}
                        key={productAddition.iri}
                        text={productAddition.title}
                        onClick={onProductAdditionClicked}
                    />
                );
            })
        );
    }

    function renderNoteInput() {
        if (onCustomerCommentChanged === _.noop) {
            return null;
        }

        return (
            <NoteInput
                onCustomerCommentChanged={onCustomerCommentChanged}
                customerComment={orderProduct.customerComment}
            />
        );
    }

    function renderCounter() {
        if (displayOnly) {
            return (
                <span className={styles.productQuantity}>
                    {quantity}
                </span>
            );
        }

        return (
            <Counter
                counter={quantity}
                onIncrementClicked={onIncrementClicked}
                onDecrementClicked={onDecrementClicked}
            />
        );
    }

    return (
        <div className={styles.orderPosition}>
            <div className={styles.positionSide}>
                <Headline
                    type={HeadlineType.headline2}
                    title={product.name}
                />
                {renderAdditions()}
            </div>
            <div className={styles.positionSide}>
                <div className={styles.priceAndQuantity}>
                    <div>
                        {renderCounter()}
                    </div>
                    <div>
                        <Price
                            value={positionSum}
                            color={PriceColor.orange}
                        />
                    </div>
                </div>
                {renderNoteInput()}
            </div>
        </div>
    );
};

OrderPosition.propTypes = propTypes;

export default OrderPosition;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                  from '@components/PropTypes';
import IconType                   from '@stateless/atomic/Icon/IconType';
import StatelessInfoOverlay       from '@stateless/composed/InfoOverlay';
import { selectActiveAppSetting } from '@store/selectors/appSettings';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const InfoOverlay = ({
    onCloseClick = _.noop,
    open         = false,
}) => {
    const appSetting   = useSelector(selectActiveAppSetting);
    const infoBoxItems = [
        {
            titleKey:         'deliveryTitle',
            textKey:          'deliveryText',
            textKeyParameter: {
                minimumOrderValue: appSetting?.minimumOrderValueForDeliveries,
            },
            iconType:         IconType.delivery,
        },
        {
            titleKey: 'bigOrdersTitle',
            textKey:  'bigOrdersText',
            iconType: IconType.forms,
        },
        {
            titleKey: 'cutWishTitle',
            textKey:  'cutWishText',
            iconType: IconType.pizza,
        },
        {
            titleKey: 'heatedDeliveryBoxTitle',
            textKey:  'heatedDeliveryBoxText',
            iconType: IconType.flame,
        },
        {
            titleKey: 'notGlutenFreeTitle',
            textKey:  'notGlutenFreeText',
            iconType: IconType.allergy,
        },
    ];

    return (
        <StatelessInfoOverlay
            onClose={onCloseClick}
            open={open}
            infoItems={infoBoxItems}
        />
    );
};

InfoOverlay.propTypes = propTypes;

export default InfoOverlay;

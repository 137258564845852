//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes   from '@components/PropTypes';
import CircleIcon  from '@stateless/atomic/CircleIcon';
import CircleColor from '@stateless/atomic/CircleIcon/CircleColor';
import IconType    from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    counter:            PropTypes.number,
    onDecrementClicked: PropTypes.func,
    onIncrementClicked: PropTypes.func,
};

const Counter = ({
    counter            = 0,
    onIncrementClicked = _.noop,
    onDecrementClicked = _.noop,
}) => {
    return (
        <div className={styles.counter}>
            <CircleIcon
                iconType={IconType.minus}
                circleColor={CircleColor.gray}
                onClick={onDecrementClicked}
            />
            <p>
                {counter}
            </p>
            <CircleIcon
                iconType={IconType.plus}
                circleColor={CircleColor.orange}
                onClick={onIncrementClicked}
            />
        </div>
    );
};

Counter.propTypes = propTypes;

export default Counter;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _         from 'lodash';
import PropTypes from 'prop-types';

import Carousel    from '@stateless/atomic/Carousel';
import ArrowColor  from '@stateless/atomic/Carousel/ArrowColor';
import IconType    from '@stateless/atomic/Icon/IconType';
import ProductItem from '@stateless/atomic/ProductItem';

import styles from './styles.module.scss';

const propTypes = {
    createAddProductClicked: PropTypes.func,
    isMobile:                PropTypes.bool,
    products:                PropTypes.array,
};

const ProductSlider = ({
    products                = [],
    isMobile                = false,
    createAddProductClicked = _.noop,
}) => {
    const carouselOptions       = {
        width:       'calc(100% - 100px)',
        autoHeight:  true,
        perMove:     1,
        perPage:     4,
        gap:         20,
        pagination:  false,
        breakpoints: {
            1300: {
                perPage: 3,
            },
        },
    };
    const mobileCarouselOptions = {
        width:       '100%',
        arrows:      false,
        autoHeight:  true,
        perMove:     1,
        gap:         20,
        perPage:     3,
        pagination:  true,
        breakpoints: {
            800: {
                perPage: 2,
            },
            550: {
                perPage: 1,
            },
        },
    };

    function getOptions() {
        return isMobile
            ? mobileCarouselOptions
            : carouselOptions;
    }

    function renderProducts() {
        return _.map(products, (product) => {
            const addProductToCartClicked = createAddProductClicked(product);

            return (
                <ProductItem
                    key={product.iri}
                    product={product}
                    small={true}
                    addProductToCartClicked={addProductToCartClicked}
                />
            );
        });
    }

    return (
        <Carousel
            className={styles.productSlider}
            options={getOptions()}
            arrowIcon={IconType.chevronRight}
            arrowColor={ArrowColor.black}
        >
            {renderProducts()}
        </Carousel>
    );
};

ProductSlider.propTypes = propTypes;

export default ProductSlider;

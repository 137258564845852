//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call } from 'redux-saga/effects';
import { put }  from 'redux-saga/effects';

import * as Api             from '@api/index';
import Hydra                from '@helper/Hydra';
import { TimeSlotsActions } from '@slices/timeSlots';

function* fetchTimeSlots(action) {
    const response = yield call(Api.context.timeSlots.fetch);

    if (response.ok) {
        const timeSlots = Hydra.getMembersFromResponse(response.data);

        yield put(TimeSlotsActions.fetchTimeSlotsSucceeded({
            timeSlots,
        }));
    } else {
        yield put(TimeSlotsActions.fetchTimeSlotsFailed());
    }
}

export default {
    fetchTimeSlots,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';
import InfoBlock from '@stateless/atomic/InfoBlock';
import Overlay   from '@stateless/atomic/Overlay';

import styles from './styles.module.scss';

const propTypes = {
    infoItems: PropTypes.array,
    onClose:   PropTypes.func,
    open:      PropTypes.bool,
};

const InfoOverlay = ({
    onClose   = _.noop,
    open      = false,
    infoItems = [],
}) => {
    function renderInfoBlocks() {
        return infoItems.map((infoItem, index) => {
            return (
                <InfoBlock
                    key={infoItem.titleKey}
                    infoItem={infoItem}
                />
            );
        });
    }

    return (
        <Overlay
            onCloseClick={onClose}
            open={open}
        >
            <div className={styles.infoBlocksContainer}>
                {renderInfoBlocks()}
            </div>
        </Overlay>
    );
};

InfoOverlay.propTypes = propTypes;

export default InfoOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Overlay              from '@constants/Overlay';
import { OrdersActions }    from '@slices/orders';
import { OverlayActions }   from '@slices/overlay';
import { ProductsActions }  from '@slices/products';
import StatelessProductGrid from '@stateless/composed/ProductGrid';
import { selectProducts }   from '@store/selectors/products';

const propTypes = {};

const ProductGrid = () => {
    const products = useSelector(selectProducts);
    const dispatch = useDispatch();

    function createOnProductInfoClicked(product) {
        return () => {
            dispatch(ProductsActions.setProductInDetail({
                product,
            }));
            dispatch(OverlayActions.openOverlay({
                overlay: Overlay.productInfo,
            }));
        };
    }

    function createOnAddToCartClicked(product) {
        return () => {
            dispatch(OrdersActions.addProductToCurrentOrder({
                product,
            }));
        };
    }

    return (
        <StatelessProductGrid
            products={products}
            createOnProductInfoClicked={createOnProductInfoClicked}
            createOnAddToCartClicked={createOnAddToCartClicked}
        />
    );
};

ProductGrid.propTypes = propTypes;

export default ProductGrid;

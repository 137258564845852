//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes      from '@components/PropTypes';
import CheckoutButton from '@connected/CheckoutButton';

import styles from './styles.module.scss';

const propTypes = {
    disabled:   PropTypes.bool,
    onClick:    PropTypes.func,
    onValidate: PropTypes.func,
    trackText:  PropTypes.string,
};

const TrackButton = ({
    disabled   = false,
    onClick    = _.noop,
    trackText  = '',
    onValidate = _.noop,
}) => {
    const [clicked, setClicked] = useState(false);

    function onButtonClicked() {
        if (
            disabled === true ||
            onValidate() === false
        ) {
            return;
        }

        setClicked(true);
        setTimeout(
            () => {
                setClicked(false);
            },
            2000,
        );
        onClick();
    }

    return (
        <div
            className={styles.checkoutButton}
            onClick={onButtonClicked}
        >
            <div
                className={classNames(
                    styles.buttonTrack,
                    {
                        [styles.clicked]:  clicked === true,
                        [styles.disabled]: disabled === true,
                    },
                )}
            >
                <CheckoutButton
                    disabled={disabled}
                />
                <div className={styles.trackText}>
                    <p>
                        {trackText}
                    </p>
                </div>
            </div>
        </div>
    );
};

TrackButton.propTypes = propTypes;

export default TrackButton;

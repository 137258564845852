//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes     from '@components/PropTypes';
import TextInput     from '@stateless/atomic/TextInput';
import TextInputType from '@stateless/atomic/TextInput/TextInputType';

import styles from './styles.module.scss';

const propTypes = {
    labelText: PropTypes.string,
    onBlur:    PropTypes.func,
    onChange:  PropTypes.func,
    onFocus:   PropTypes.func,
    onKeyDown: PropTypes.func,
    required:  PropTypes.bool,
    type:      PropTypes.oneOfObjectValues(TextInputType),
    value:     PropTypes.string,
};

const LabeledTextInput = ({
    labelText = '',
    onBlur    = _.noop,
    onChange  = _.noop,
    onFocus   = _.noop,
    onKeyDown = _.noop,
    required  = false,
    value     = '',
    type      = TextInputType.text,
}) => {
    const requiredSign = required
        ? '*'
        : null;
    const multiline    = type === TextInputType.textarea;

    return (
        <div className={styles.labeledTextInput}>
            <label>
                {labelText}{requiredSign}
            </label>
            <TextInput
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                type={type}
                multiline={multiline}
            />
        </div>
    );
};

LabeledTextInput.propTypes = propTypes;

export default LabeledTextInput;

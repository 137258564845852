//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import moment from 'moment';

const basePath = [
    'deliveryExceptions',
];

export const selectDeliveryExceptions = (state) => {
    return _.get(state, [...basePath, 'deliveryExceptions'], []);
};

export const selectDeliveryExceptionByTimeSlot = (timeSlot) => (state) => {
    if (!timeSlot) {
        return null;
    }

    const deliveryExceptions = selectDeliveryExceptions(state);

    return _.find(deliveryExceptions, (deliveryException) => {
        const exceptionStart = moment(deliveryException.fromDateTime);
        const exceptionEnd   = moment(deliveryException.toDateTime);
        const timeSlotStart  = moment(timeSlot.startDateTime);
        const timeSlotEnd    = moment(timeSlot.endDateTime);

        return (
            exceptionStart.isSameOrBefore(timeSlotStart) &&
            exceptionEnd.isSameOrAfter(timeSlotEnd)
        );
    });
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import moment from 'moment';

class TimeSlotHelper {
    static getDayShortKeyByDate(date) {
        const dateMoment   = moment(date);
        const dayShortKeys = [
            'sundayShort',
            'mondayShort',
            'tuesdayShort',
            'wednesdayShort',
            'thursdayShort',
            'fridayShort',
            'saturdayShort',
        ];

        return dayShortKeys[dateMoment.day()];
    }

    static getDateString(dateString) {
        const date          = moment(dateString);
        const day           = date.date();
        const monthShortKey = this.getMonthShortKeyByDate(date);
        const monthShort    = I18n.t(monthShortKey);

        return `${day}. ${monthShort}`;
    }

    static getDateStringLong(dateString) {
        const date          = moment(dateString);
        const day           = date.date();
        const monthShortKey = this.getMonthShortKeyByDate(date);
        const monthShort    = I18n.t(monthShortKey);
        const year          = date.year();

        return `${day}. ${monthShort}. ${year}`;
    }

    static getMonthShortKeyByDate(date) {
        const dateMoment     = moment(date);
        const monthShortKeys = [
            'januaryShort',
            'februaryShort',
            'marchShort',
            'aprilShort',
            'mayShort',
            'juneShort',
            'julyShort',
            'augustShort',
            'septemberShort',
            'octoberShort',
            'novemberShort',
            'decemberShort',
        ];

        return monthShortKeys[dateMoment.month()];
    }
}

export default TimeSlotHelper;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import CheckoutOverlay             from '@connected/CheckoutOverlay';
import InfoOverlay                 from '@connected/InfoOverlay';
import MobileRestaurantInfoOverlay from '@connected/MobileRestaurantInfoOverlay';
import ProductInfoOverlay          from '@connected/ProductInfoOverlay';
import TooBigOrderOverlay          from '@connected/TooBigOrderOverlay';
import Overlay                     from '@constants/Overlay';
import KeyboardEvent               from '@helper/KeyboardEvent';
import { OverlayActions }          from '@slices/overlay';
import selectOverlay               from '@store/selectors/overlay';

const OverlayManger = () => {
    const dispatch       = useDispatch();
    const currentOverlay = useSelector(selectOverlay);
    const overlayMapping = {
        // eslint-disable react/react-in-jsx-scope
        [Overlay.checkout]:             <CheckoutOverlay />,
        [Overlay.mobileRestaurantInfo]: <MobileRestaurantInfoOverlay />,
        [Overlay.info]:                 <InfoOverlay />,
        [Overlay.productInfo]:          <ProductInfoOverlay />,
        [Overlay.tooBigOrder]:          <TooBigOrderOverlay />,
        // eslint-enable react/react-in-jsx-scope
    };

    function renderOverlay(key) {
        const open                  = key === currentOverlay;
        const overlayMappingElement = overlayMapping[key];

        function onCloseClicked() {
            dispatch(OverlayActions.closeOverlay({
                overlay: key,
            }));
        }

        function onKeyPress(event) {
            if (KeyboardEvent.isEscape(event)) {
                onCloseClicked();
            }
        }

        const clonedElement = React.cloneElement(
            overlayMappingElement,
            {
                key,
                open,
                onCloseClick: onCloseClicked,
            },
        );

        return (
            <div
                key={key}
                onKeyDown={onKeyPress}
            >
                {clonedElement}
            </div>
        );
    }

    return _.map(Object.keys(Overlay), renderOverlay);
};

export default OverlayManger;

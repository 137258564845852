//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { parseInt } from 'lodash';

import DomEvents           from '@constants/DomEvents';
import EventListenerEffect from '@helper/EventListener';
import breakpoints         from '@styles/breakpoints.scss';

const withScreenSize = (WrappedComponent) => {
    const Component = (props) => {
        const [isMobile, setIsMobile]   = useState(false);
        const [isTablet, setIsTablet]   = useState(false);
        const [isDesktop, setIsDesktop] = useState(false);

        function updateScreenSizeFlags() {
            const width        = window.innerWidth;
            const breakpointSm = parseInt(breakpoints.screenSm
                .trim()
                .replace('px', ''));
            const breakpointMd = parseInt(breakpoints.screenMd
                .trim()
                .replace('px', ''));

            if (width < breakpointSm) {
                setIsMobile(true);
                setIsTablet(false);
                setIsDesktop(false);

                return;
            }

            if (width < breakpointMd) {
                setIsMobile(false);
                setIsTablet(true);
                setIsDesktop(false);

                return;
            }

            setIsMobile(false);
            setIsTablet(false);
            setIsDesktop(true);
        }

        useEffect(() => {
            updateScreenSizeFlags();

            return EventListenerEffect.subscribeAndRemove([
                EventListenerEffect.eventWithCallback(DomEvents.resize, updateScreenSizeFlags),
            ]);
        }, []);

        return (
            <WrappedComponent
                {...props}
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
            />
        );
    };

    return Component;
};

export default withScreenSize;

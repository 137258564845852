//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ProductType from '@constants/ProductType';

const basePath = [
    'products',
];

export const selectProducts = (state) => {
    return _.get(state, [...basePath, 'products'], []);
};

export const selectPizza = (state) => {
    const products = selectProducts(state);

    return _.filter(products, {
        type: ProductType.pizza,
    });
};

export const selectDrinks = (state) => {
    const products = selectProducts(state);

    return _.filter(products, {
        type: ProductType.drink,
    });
};

export const selectProductInDetail = (state) => {
    return _.get(state, [...basePath, 'productInDetail']);
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useSelector } from 'react-redux';

import PropTypes                            from '@components/PropTypes';
import StatelessMobileRestaurantInfoOverlay from '@stateless/composed/MobileRestaurantInfoOverlay';
import { selectCurrentRestaurant }          from '@store/selectors/restaurants';

const propTypes = {
    onCloseClick: PropTypes.func,
    open:         PropTypes.bool,
};

const MobileRestaurantInfoOverlay = ({
    onCloseClick = _.noop,
    open         = false,
}) => {
    const restaurant = useSelector(selectCurrentRestaurant);

    return (
        <StatelessMobileRestaurantInfoOverlay
            onClose={onCloseClick}
            open={open}
            restaurant={restaurant}
        />
    );
};

MobileRestaurantInfoOverlay.propTypes = propTypes;

export default MobileRestaurantInfoOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    restaurant: PropTypes.object,
};

const RestaurantAddress = ({
    restaurant = null,
}) => {
    function renderAddress() {
        if (!restaurant) {
            return null;
        }

        const name    = restaurant.name;
        const address = restaurant.address;

        if (!address) {
            return null;
        }

        const street      = address.street;
        const houseNumber = address.houseNumber;
        const postalCode  = address.postalCode;
        const city        = address.city;

        return (
            <div>
                <p>
                    {name}
                </p>
                <p>
                    {street} {houseNumber}
                </p>
                <p>
                    {postalCode} {city}
                </p>
            </div>
        );
    }

    return (
        <div className={styles.restaurantAddress}>
            {renderAddress()}
        </div>
    );
};

RestaurantAddress.propTypes = propTypes;

export default RestaurantAddress;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';

const propTypes = {
    className: PropTypes.string,
    href:      PropTypes.string,
    iconType:  PropTypes.iconType,
    target:    PropTypes.string,
};

const LinkIcon = ({
    href      = '',
    iconType  = null,
    target    = null,
    className = '',
}) => {
    return (
        <a
            className={className}
            href={href}
            target={target}
            rel="noreferrer"
        >
            <Icon
                iconType={iconType}
            />
        </a>
    );
};

LinkIcon.propTypes = propTypes;

export default LinkIcon;

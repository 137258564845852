//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import { AppSettingsActions }      from '@slices/appSettings';
import { ProductAdditionsActions } from '@slices/productAdditions';
import { TimeSlotsActions }        from '@slices/timeSlots';

function* windowGotFocus() {
    yield put(TimeSlotsActions.fetchTimeSlots());
    yield put(AppSettingsActions.fetchAppSettings());
    yield put(ProductAdditionsActions.fetchProductAdditions());
}

function* windowBecameVisible() {
    // You may do something here (e.g. fetching data)
}

export default {
    windowGotFocus,
    windowBecameVisible,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';

import ContainerLoadingOverlay from '@stateless/atomic/ContainerLoadingOverlay';
import { selectIsReserving }   from '@store/selectors/loading';

const TimeSlotSelectorLoadingOverlay = () => {
    const isReserving = useSelector(selectIsReserving);

    return (
        <ContainerLoadingOverlay loading={isReserving} />
    );
};

export default TimeSlotSelectorLoadingOverlay;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import { useStripe }   from '@stripe/react-stripe-js';
import { useElements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory }  from 'react-router';

import PropTypes                       from '@components/PropTypes';
import { OrdersActions }               from '@slices/orders';
import { selectCurrentOrderConfirmed } from '@store/selectors/orders';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.children,
};

const StripeCheckoutForm = ({
    children = null,
}) => {
    const stripe       = useStripe();
    const elements     = useElements();
    const dispatch     = useDispatch();
    const { search }   = useLocation();
    const { pathname } = useLocation();
    const history      = useHistory();
    const confirmed    = useSelector(selectCurrentOrderConfirmed);

    useEffect(() => {
        const urlParams                 = new URLSearchParams(search);
        const paymentIntent             = urlParams.get('payment_intent');
        const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
        const redirectStatus            = urlParams.get('redirect_status');

        if (
            paymentIntent &&
            paymentIntentClientSecret &&
            redirectStatus
        ) {
            if (redirectStatus === 'succeeded') {
                history.replace({
                    pathname,
                });
                dispatch(OrdersActions.submitPaymentSucceeded());
            } else {
                dispatch(OrdersActions.submitPaymentFailed());
            }
        }
    }, [search]);

    async function handleSubmit(event) {
        event?.preventDefault();

        if (!confirmed) {
            dispatch(OrdersActions.confirmOrder());

            return;
        }

        if (
            !stripe ||
            !elements
        ) {
            return;
        }

        const { error: submitError } = await elements.submit();

        if (submitError) {
            return;
        }

        dispatch(OrdersActions.submitPayment());

        const urlWithoutSearchParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        const { error }              = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: urlWithoutSearchParams,
            },
        });

        if (error) {
            console.log('StripeCheckoutForm: Error ', error);
            dispatch(OrdersActions.submitPaymentFailed());
        }
    }

    useEffect(() => {
        if (confirmed) {
            handleSubmit();
        }
    }, [confirmed]);

    return (
        <div className={styles.stripePayment}>
            <form onSubmit={handleSubmit}>
                {children}
            </form>
        </div>
    );
};

StripeCheckoutForm.propTypes = propTypes;

export default StripeCheckoutForm;
